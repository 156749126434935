import { BehaviorSubject, combineLatest, Observable } from "rxjs"
import { map } from "rxjs/operators"
import { ActiveUsersStore } from "../data/ActiveUsersStore"
import { TaskStore } from "../data/TaskStore"
import { EmergencyAlarmTask } from "../domain/entities/DispatcherTask"
import { ConductorLocation } from "../domain/entities/Location"
import { ClientApiImpl } from "../network/ClientApi"
import { convertLocation } from "../network/DTOs"


export type ShowEmergencyAlarmsModel = {
    state: Observable<EmergencyAlarmState>
    actions: {
        closeCurrentDialog: () => Promise<void>
        displayAlarm: (id: string) => void
        markAlarmAsDone: (id: string) => Promise<void>
    }
}

export type EmergencyAlarmState = {
    alarms: EmergencyAlarmTask[]
    displayedAlarm: AlarmInformation | undefined
    numberOfOpenAlarms: number
}

export type AlarmInformation = {
    id: string
    name: string
    personId: string
    initialLocation: ConductorLocation
    currentLocation: ConductorLocation
}

export type ShowEmergencyAlarms = () => ShowEmergencyAlarmsModel

export const createShowEmergencyAlarmsUseCase = (
    taskStore: TaskStore,
    activeUsersStore: ActiveUsersStore,
    clientApi: ClientApiImpl
): ShowEmergencyAlarms => () => {

    let selection = new BehaviorSubject<string | undefined>(undefined)

    let noticedAlarms: string[] = []

    taskStore.observeTasks().subscribe(tasks => {
        const newAlarms = tasks.filter(it => noticedAlarms.find(id => it.id === id) === undefined)

        if (newAlarms.length > 0) {
            if (selection.value !== newAlarms[newAlarms.length - 1].id) {
                selection.next(newAlarms[newAlarms.length - 1].id)
            }
        }
    })

    let state: Observable<EmergencyAlarmState> = combineLatest(taskStore.observeTasks(), selection).pipe(map(val => {
        const [tasks, currentSelection] = val

        const alarmTasks: EmergencyAlarmTask[] = tasks.filter(task => task.type === 'emergency-alarm')

        let alarm: AlarmInformation | undefined = undefined

        if (currentSelection) {
            const currentAlarm = alarmTasks.find(it => it.id === currentSelection)
            if (currentAlarm && currentAlarm.status === 'open') {
                const users = activeUsersStore.getActiveUsers()

                const user = users.find(it => it.id === currentAlarm.by)

                let currentLocation: ConductorLocation = { type: 'unknown' }
                if (user?.state.location) {
                    currentLocation = convertLocation(user?.state.location.value)
                }

                alarm = {
                    id: currentAlarm.id,
                    name: user?.firstName + " " + user?.lastName,
                    personId: currentAlarm.by,
                    initialLocation: currentAlarm.triggeredAtLocation,
                    currentLocation
                }

            }
        }

        return {
            alarms: alarmTasks,
            displayedAlarm: alarm,
            numberOfOpenAlarms: alarmTasks.filter(it => it.status === 'open').length
        }
    }))

    return {
        state: state,
        actions: {
            closeCurrentDialog: () => {
                const currentSelection = selection.value
                if (currentSelection !== undefined) {
                    noticedAlarms.push(currentSelection)                
                }
                selection.next(undefined)
            },
            displayAlarm: id => selection.next(id),
            markAlarmAsDone: id => {
                const task = taskStore.getOpenTasks().find(it => it.id === id)
                if (task) {
                    //taskStore.saveTask({ ...task, status: 'closed' })
                    clientApi.resolveAlert(task.by, task.alarmStarted).then((it) => {
                        if (it.success === false) {
                            alert("Hälytyksen sulkeminen epäonnistui. Ole hyvä ja yritä uudestaan") // TODO: this is not a proper solution.  
                        } 
                    })
                    selection.next(undefined)
                }                
            }
        }
    } as ShowEmergencyAlarmsModel
}

