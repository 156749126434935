import { AuthResponse } from "../network/ClientApi"

export class PersistentStorage {

    private AUTH_STORAGE_KEY = 'kota-web-auth'

    getAuthDetails(): AuthResponse | undefined {
        const details = window.localStorage.getItem(this.AUTH_STORAGE_KEY)
        if (details) {
            const result = JSON.parse(details)
            if (result) {
                return result
            } else {
                return undefined
            }
        } else {
            return undefined
        }
    }

    saveAuthDetails(details: AuthResponse) {
        window.localStorage.setItem(this.AUTH_STORAGE_KEY, JSON.stringify(details))
    }

    removeAuthDetails() {
        window.localStorage.removeItem(this.AUTH_STORAGE_KEY)
    }
}