import { BehaviorSubject } from "rxjs"
import { GeneralNotification } from "../domain/entities/GeneralNotification"

export class NotificationStore {

    private notifications = new BehaviorSubject<GeneralNotification[]>([])

    private lastUpdated: number | undefined = undefined

    setNotifications(newNotifications: GeneralNotification[]) {
        this.notifications.next(newNotifications)
        this.lastUpdated = Date.now()
    }

    addNotification(notification: GeneralNotification) {
        const old = this.notifications.value
        this.notifications.next([...old, notification])
        this.lastUpdated = Date.now()
    }

    getNotifications(): BehaviorSubject<GeneralNotification[]> {
        return this.notifications
    }

    getLastUpdated(): number | undefined {
        return this.lastUpdated
    }
}