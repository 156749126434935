import React, { useState, useEffect, useContext } from 'react';
import MaterialTable, { Column } from 'material-table';
import moment from 'moment';
import "moment/locale/fi";
import { DomainContext } from '../../../DomainContext';
import { forwardRef } from 'react';
import AddBox from '@material-ui/icons/AddBox';
import ArrowUpward from '@material-ui/icons/ArrowUpward';
import Check from '@material-ui/icons/Check';
import ChevronLeft from '@material-ui/icons/ChevronLeft';
import ChevronRight from '@material-ui/icons/ChevronRight';
import Clear from '@material-ui/icons/Clear';
import DeleteOutline from '@material-ui/icons/DeleteOutline';
import Edit from '@material-ui/icons/Edit';
import FilterList from '@material-ui/icons/FilterList';
import FirstPage from '@material-ui/icons/FirstPage';
import LastPage from '@material-ui/icons/LastPage';
import Remove from '@material-ui/icons/Remove';
import SaveAlt from '@material-ui/icons/SaveAlt';
import Search from '@material-ui/icons/Search';
import ViewColumn from '@material-ui/icons/ViewColumn';
import { Icons } from 'material-table';
import { ActiveUser } from '../../../domain/entities/ActiveUser';

const tableIcons: Icons = {
    Add: forwardRef((props, ref) => <AddBox {...props} ref={ref} />),
    Check: forwardRef((props, ref) => <Check {...props} ref={ref} />),
    Clear: forwardRef((props, ref) => <Clear {...props} ref={ref} />),
    Delete: forwardRef((props, ref) => <DeleteOutline {...props} ref={ref} />),
    DetailPanel: forwardRef((props, ref) => <ChevronRight {...props} ref={ref} />),
    Edit: forwardRef((props, ref) => <Edit {...props} ref={ref} />),
    Export: forwardRef((props, ref) => <SaveAlt {...props} ref={ref} />),
    Filter: forwardRef((props, ref) => <FilterList {...props} ref={ref} />),
    FirstPage: forwardRef((props, ref) => <FirstPage {...props} ref={ref} />),
    LastPage: forwardRef((props, ref) => <LastPage {...props} ref={ref} />),
    NextPage: forwardRef((props, ref) => <ChevronRight {...props} ref={ref} />),
    PreviousPage: forwardRef((props, ref) => <ChevronLeft {...props} ref={ref} />),
    ResetSearch: forwardRef((props, ref) => <Clear {...props} ref={ref} />),
    Search: forwardRef((props, ref) => <Search {...props} ref={ref} />),
    SortArrow: forwardRef((props, ref) => <ArrowUpward {...props} ref={ref} />),
    ThirdStateCheck: forwardRef((props, ref) => <Remove {...props} ref={ref} />),
    ViewColumn: forwardRef((props, ref) => <ViewColumn {...props} ref={ref} />),
}

const columns: Column<any>[] = [
    { title: 'Nimi', field: 'name' },
    { title: 'Tila', field: 'state', lookup: { 'Working': 'Töissä', 'OffDuty': 'Ei työvuorossa', 'OnBreak': 'Tauolla' }, defaultFilter: ['Working', 'OnBreak'] },
    { title: 'Aika', field: 'time', filtering: false, defaultSort: 'desc' },
    { title: 'Sitten', field: 'ago', filtering: false },
    { title: 'Työvuoro', field: 'workShift' },
    { title: 'Linja', field: 'line' },
    { title: 'Sijainti', field: 'loc' },
    { title: 'Juna', field: 'train' },
    { title: 'Runko', field: 'vehicle' },
]

export const MainView = () => {
    const [conductors, setConductors] = useState<ActiveUser[]>([])

    const { activeUsersStore } = useContext(DomainContext)

    useEffect(() => {
        const subscription = activeUsersStore.observeActiveUsers().subscribe(setConductors)
        return function cleanup() {
            subscription.unsubscribe()
        }
    }, [activeUsersStore])

    const data = conductors.map(r => {
        return {
            name: r.firstName + ' ' + r.lastName,
            state: r.state.status.value.status,
            time: moment(r.lastSeen).format("YYYY-MM-DD HH:mm:ss"),
            ago: moment(r.lastSeen).fromNow(),
            workShift: r.state.status.value.workshift,
            line: r.state.location?.value?.train?.line ?? "-",
            loc: extractPosition(r.state.location?.value.position),
            train: r.state.location?.value?.train?.trainNumber ?? "-",
            vehicle: r.state.location?.value?.train?.vehicle ?? "-",
        }
    })

    return (
        <div style={{ maxWidth: '100%' }}>
            <MaterialTable
                icons={tableIcons}
                options={{
                    exportButton: false,
                    filtering: true,
                    selection: false,
                    searchFieldAlignment: 'right',
                    pageSizeOptions: [50, 100, 200],
                    pageSize: 50,
                    emptyRowsWhenPaging: false
                }}
                localization={{
                    pagination: {
                        labelDisplayedRows: '{from}-{to} / {count}'
                    },
                    toolbar: {
                        nRowsSelected: '{0} riviä valittu',
                        searchTooltip: "Hae",
                        searchPlaceholder: "Hae"
                    },
                    body: {
                        emptyDataSourceMessage: 'Ei konduktöörejä',
                        filterRow: {
                            filterTooltip: 'Suodatin'
                        }
                    }
                }}
                columns={columns}
                data={data}
                title="Konduktöörit"
            />
        </div>
    )
}

function extractPosition(position: any): string {
    if (!position) {
        return "-"
    } else if (position.station) {
        return position.station
    } else if (position.between) {
        var stationIdFrom = position.between.from
        var stationIdTo = position.between.to
        return stationIdFrom + " -> " + stationIdTo
    } else {
        return "-"
    }
}