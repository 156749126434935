import { of, Subscription, BehaviorSubject } from 'rxjs';
import { delay, tap, repeat } from 'rxjs/operators';
import config from '../config';
import { PersistentStorage } from './PersistentStorage';

export type ConnectionStatus = 'connected' | 'disconnected' | 'reconnecting'

export class EventWebsocket {

    private ws: any = undefined

    private keepAlivePinger: Subscription | undefined = undefined

    status = new BehaviorSubject<ConnectionStatus>('disconnected')

    constructor(readonly storage: PersistentStorage) { }

    private observer: ((message: any) => void) | undefined = undefined

    private state: 'started' | 'ended' | undefined = undefined 

    start(observer: (message: any) => void) {
        this.state = 'started'
        this.observer = observer
        this.startListeningMessages()
    }

    private startListeningMessages() {
        if (this.ws) {
            this.ws.close()
        }

        const url = config.clientWebSocketApi + '?token=' + this.storage.getAuthDetails()?.authorization + '&client=dispatcher-web'

        this.ws = new WebSocket(url)
        this.ws.onmessage = (evt: MessageEvent) => {
            const data: any = JSON.parse(evt.data)
            if (this.observer !== undefined) {
                this.observer(data)
            } else {
                this.ws.close()
                this.status.next('disconnected')
            }
        }

        this.ws.onclose = (event: any) => {
            if (this.state === 'started') {
                this.status.next('reconnecting')
            } else {
                this.status.next('disconnected')
            }
        }

        this.ws.onopen = (event: any) => {
            this.status.next('connected')
        }

        this.pingPeriodiallyToKeepConnectionAlive()
    }

    // TODO: call this is auth expires
    stop() {
        this.state = 'ended'
        if (this.ws !== undefined) {
            this.ws.close()
        }
    }

    async pingPeriodiallyToKeepConnectionAlive() {
        const twoMinutesinMs = 1000 * 60 * 2
        if (this.keepAlivePinger) {
            this.keepAlivePinger.unsubscribe()
        }

        this.keepAlivePinger = of({})
            .pipe(
                delay(twoMinutesinMs),
                tap(() => {                    
                    this.ws.send(JSON.stringify({ action: 'alive', message: 'Ping' }))                    
                    if (this.ws.readyState === 3) {
                        if (this.state === 'started') {                        
                            this.status.next('reconnecting')
                            this.startListeningMessages()
                        } else {
                            this.status.next('disconnected')
                        }                        
                    }
                }),
                repeat()
            )
            .subscribe()
    }
}