import React from 'react';
import { makeStyles, ListItem, ListItemAvatar, Avatar, ListItemText } from '@material-ui/core';
import AccountBoxIcon from '@material-ui/icons/AccountBox';
import moment from 'moment-timezone';
import { Message } from '../../../domain/entities/Message';

const useStyles = makeStyles((theme) => ({
    root: {
        width: '100%',
        backgroundColor: theme.palette.background.paper,
    },
    inline: {
        display: 'inline',
    },
    icon: {
        '& svg': { fontSize: 150 }
    },
    green: {
        color: "#FFFFFF",
        backgroundColor: "#00B451",
    },
    blue: {
        color: theme.palette.getContrastText("#244496"),
        backgroundColor: "#244496",
    },
}))

export const MessageElement = (props: { message: Message }) => {
    const classes = useStyles();

    const time = moment(props.message.timestamp).tz("Europe/Helsinki").format("HH:mm")

    let name = props.message.from.name
    let avatar: any = (<Avatar className={classes.green}><AccountBoxIcon /></Avatar>)

    if (props.message.type === 'general-notification' || props.message.from.type === 'dispatcher') {
        name = "Ohjaus (" + name + ")"
        avatar = (<Avatar className={classes.blue}>OH</Avatar>)
    }

    return (
        <ListItem key={props.message.timestamp} style={props.message.savedToBackend ? {} : { backgroundColor: '#EAEAEA' }}>
            <ListItemAvatar>
                {avatar}
            </ListItemAvatar>
            <ListItemText style={{ whiteSpace: 'pre-line' }} primary={name + " - " + time} secondary={props.message.message} />
        </ListItem>
    )
}