import { BehaviorSubject } from "rxjs"
import { GroupChannel } from "../domain/entities/GroupChannel"

export class GroupChannelStore {
    private channels = new BehaviorSubject<GroupChannel[]>([])

    private lastUpdated: number | undefined = undefined

    setChannels(newChannels: GroupChannel[]) {
        this.channels.next(newChannels)
        this.lastUpdated = Date.now()
    }

    getChannels(): GroupChannel[] {
        return this.channels.getValue()
    }

    getChannel(id: string): GroupChannel | undefined {
        return this.channels.getValue().find(channel => channel.id === id)
    }

    observeChannels(): BehaviorSubject<GroupChannel[]> {
        return this.channels
    }

    getLastUpdated(): number | undefined {
        return this.lastUpdated
    }
}