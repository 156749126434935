import React from 'react';
import { EventWebsocket } from './data/EventWebsocket';
import { listenConductorEvents } from './usecases/ListenConductorEvents';
import { GroupChannelStore } from './data/GroupChannelStore';
import { ClientApiImpl, AuthResponse } from './network/ClientApi';
import { ClockImpl } from './utility/Clock';
import { GroupMessageStoreImpl } from './data/GroupMessageStore';
import { NotificationStore } from './data/NotificationStore';
import { PersonalMessageStore } from './data/PersonalMessageStore';
import { PersistentStorage } from './data/PersistentStorage';
import { BehaviorSubject, of } from 'rxjs';
import { ChatInGroupChannel, createChatInGroupChannelUseCase } from './usecases/ChatInGroupChannel';
import { ObserveChannels, createObserveChannelsUseCase } from './usecases/ObserveChannels';
import { tap, delay, repeat } from 'rxjs/operators';
import { ChatInPersonalChannel, createChatInPersonalChannelUseCase } from './usecases/ChatInPersonalChannel';
import { SendNotifications, createSendNotificationsUseCase } from './usecases/SendNotifications';
import { CreateNewChannel, createCreateNewChannelUsecase } from './usecases/CreateNewChannel';
import { ShowLatestMessages, createShowLatestMessagesUseCase } from './usecases/ShowLatestMessages';
import { ActiveUsersStore } from './data/ActiveUsersStore';
import { TaskStore } from './data/TaskStore';
import { createShowEmergencyAlarmsUseCase, ShowEmergencyAlarmsModel } from './usecases/ShowEmergencyAlarms';

const persistentStorage = new PersistentStorage()
const channelStore = new GroupChannelStore()
const messageStore = new GroupMessageStoreImpl()
const notificationStore = new NotificationStore()
const personalMessageStore = new PersonalMessageStore()
const taskStore = new TaskStore()
const clientApi = new ClientApiImpl(persistentStorage)
const clock = new ClockImpl()
const activeUsersStore = new ActiveUsersStore()
const eventWebSocket = new EventWebsocket(persistentStorage)

export type AuthStatus = { type: 'authenticated' } | { type: 'expired' } | { type: 'unauthenticated' } | { type: 'checking-authentication' } | { type: 'missing-permissions' }

const authenticationStatus = new BehaviorSubject<AuthStatus>({ type: 'checking-authentication' })

interface Domain {
    getAuthenticationStatus: () => BehaviorSubject<AuthStatus>
    exchangeLoginCodeToToken: (code: string) => Promise<{ success: boolean }>
    startListeningEvents: () => void,
    initialize: () => void,
    chatInGroupChannel: ChatInGroupChannel,
    chatInPersonalChannel: ChatInPersonalChannel,
    observeChannels: ObserveChannels,
    sendNotifications: SendNotifications,
    createNewChannel: CreateNewChannel,
    getConnectionStatus: () => BehaviorSubject<'connected' | 'disconnected' | 'reconnecting'>,
    showLatestMessages: ShowLatestMessages,
    showEmergencyAlarmsModel: ShowEmergencyAlarmsModel,
    activeUsersStore: ActiveUsersStore,
    channelStore: GroupChannelStore, // TODO: this should not be used directly
    groupMessageStore: GroupMessageStoreImpl,
    logout: () => void
}

export const domain: Domain = {
    getAuthenticationStatus: () => authenticationStatus,
    startListeningEvents: () => listenConductorEvents(eventWebSocket, channelStore, messageStore, notificationStore, personalMessageStore, activeUsersStore, taskStore, clientApi, clock)(),
    exchangeLoginCodeToToken: async (code: string): Promise<{ success: boolean }> => {
        const authResponse = await clientApi.login(code).catch(_error => undefined)

        if (authResponse) {
            persistentStorage.saveAuthDetails(authResponse)
            checkAuth(persistentStorage.getAuthDetails())
            if (authenticationStatus.value.type === 'authenticated') {
                listenConductorEvents(eventWebSocket, channelStore, messageStore, notificationStore, personalMessageStore, activeUsersStore, taskStore, clientApi, clock)()
            }
            return { success: true }
        } else {
            return { success: false }
        }
    },
    initialize: () => {
        checkAuth(persistentStorage.getAuthDetails())
        if (authenticationStatus.value.type === 'authenticated') {
            listenConductorEvents(eventWebSocket, channelStore, messageStore, notificationStore, personalMessageStore, activeUsersStore, taskStore, clientApi, clock)()
            periodicallyUpdateAuthStatus()
        }
    },
    chatInGroupChannel: (channelId) => createChatInGroupChannelUseCase(channelStore, messageStore, clientApi, persistentStorage)(channelId),
    chatInPersonalChannel: (personId) => createChatInPersonalChannelUseCase(personalMessageStore, clientApi, persistentStorage)(personId),
    sendNotifications: () => createSendNotificationsUseCase(notificationStore, clientApi, persistentStorage)(),
    observeChannels: () => createObserveChannelsUseCase(channelStore, messageStore, personalMessageStore, activeUsersStore)(),
    createNewChannel: () => createCreateNewChannelUsecase(clientApi)(),
    getConnectionStatus: () => eventWebSocket.status,
    showLatestMessages: () => createShowLatestMessagesUseCase(channelStore, messageStore, personalMessageStore, notificationStore)(),
    showEmergencyAlarmsModel: createShowEmergencyAlarmsUseCase(taskStore, activeUsersStore, clientApi)(),
    activeUsersStore: activeUsersStore,
    channelStore: channelStore,
    groupMessageStore: messageStore,
    logout: () => {
        persistentStorage.removeAuthDetails()
        authenticationStatus.next({ type: 'unauthenticated' })
    }
}

export const DomainContext = React.createContext<Domain>(domain)


function periodicallyUpdateAuthStatus() {
    of({}).pipe(
        tap(() => checkAuth(persistentStorage.getAuthDetails())),
        delay(1000 * 60 * 5),
        repeat()
    ).subscribe()
}

function checkAuth(details: AuthResponse | undefined) {
    if (details === undefined) {
        authenticationStatus.next({ type: 'unauthenticated' })
    } else {
        if (details.user.groups.find(group => group === 'dispatcher')) {
            if (details.expires > Date.now() + 1000 * 60 * 10) {
                authenticationStatus.next({ type: 'authenticated' })
            } else {
                authenticationStatus.next({ type: 'expired' })
            }
        } else {
            authenticationStatus.next({ type: 'missing-permissions' })
        }
    }
}