import React, { PropsWithChildren } from 'react';

export type ChannelListContextState = {
    showUnreadChannels: boolean
    showWorkShiftChannels: boolean
    showCustomChannels: boolean
    showPersonalChannels: boolean
}

const defaultState: ChannelListContextState = {
    showUnreadChannels: true,
    showWorkShiftChannels: true,
    showCustomChannels: true,
    showPersonalChannels: true,
}

export const ChannelListSettingsContext = React.createContext<{ settings: ChannelListContextState, toggle: (type: 'unread' | 'workShift' | 'custom' | 'personal') => void }>({ settings: defaultState, toggle: (type) => { } })

export const ChannelListSettingsProvider = (props: PropsWithChildren<{}>) => {
    const [settings, setSettings] = React.useState<ChannelListContextState>(defaultState);

    const toggle = (type: 'unread' | 'workShift' | 'custom' | 'personal') => {
        switch (type) {
            case 'unread': {
                setSettings({ ...settings, showUnreadChannels: !settings.showUnreadChannels })
                break
            }
            case 'workShift': {
                setSettings({ ...settings, showWorkShiftChannels: !settings.showWorkShiftChannels })
                break
            }
            case 'custom': {
                setSettings({ ...settings, showCustomChannels: !settings.showCustomChannels })
                break
            }
            case 'personal': {
                setSettings({ ...settings, showPersonalChannels: !settings.showPersonalChannels })
                break
            }
        }
    }

    return (
        <ChannelListSettingsContext.Provider value={{ settings: settings, toggle }}>
            {props.children}
        </ChannelListSettingsContext.Provider>
    )
}