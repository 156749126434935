import { GroupChannel, GroupChannelMessage } from "../domain/entities/GroupChannel";
import { Clock } from "../utility/Clock";
import { EventWebsocket } from "../data/EventWebsocket";
import { GeneralNotification } from "../domain/entities/GeneralNotification";
import { PersonalMessage } from "../domain/entities/PersonalMessage";
import { GroupChannelDTO, GroupChannelMessageDTO, GeneralNotificationDTO, PersonalMessageDTO, DispatcherTaskDTO, convertLocation, UserData, LocationDTO, Position, WorkStatus } from "../network/DTOs";
import { BehaviorSubject, of } from "rxjs";
import { tap, delay, repeat } from "rxjs/operators";
import { ActiveUser } from "../domain/entities/ActiveUser";
import { DispatcherTask, EmergencyAlarmTask } from "../domain/entities/DispatcherTask";
import { notEmpty } from "../utility/TypeUtils";
import { ConductorLocation } from '../domain/entities/Location'

interface ClientApi {
    getChatChannels: (from: number, to: number) => Promise<GroupChannelDTO[]>
    getMessages: (from: number, to: number) => Promise<GroupChannelMessageDTO[]>
    getGeneralNotifications: () => Promise<GeneralNotificationDTO[]>
    getAllPersonalMessages: (from: number, to: number) => Promise<PersonalMessageDTO[]>
    getActiveUsers: () => Promise<UserData[]>
    getAlarms: () => Promise<EmergencyAlarmTask[] | undefined>
}

interface ChatChannelStore {
    setChannels: (newChannels: GroupChannel[]) => void
    getChannels: () => GroupChannel[]
}

interface MessageStore {
    addMessage: (channelId: string, message: GroupChannelMessage) => void
    addMessages: (channelId: string, messages: GroupChannelMessage[]) => void
    addMessagesMultiChannel: (messages: GroupChannelMessage[]) => void
    getMessages: () => BehaviorSubject<Map<String, GroupChannelMessage[]>>
}

interface NotificationStore {
    setNotifications: (newNotifications: GeneralNotification[]) => void
    addNotification: (notification: GeneralNotification) => void
}

interface PersonalMessageStore {
    addMessages: (messages: PersonalMessage[]) => void
    getMessages: () => BehaviorSubject<Map<String, PersonalMessage[]>> 
}

interface ActiveUserStore {
    updateUsers: (activeUsers: ActiveUser[]) => void
}

interface TaskStore {
    getOpenTasks: () => DispatcherTask[]
    saveTasks: (tasks: DispatcherTask[]) => void
}

export type ListenConductorEvents = () => Promise<void>



export const listenConductorEvents = (
    eventWebSocket: EventWebsocket,
    channelStore: ChatChannelStore,
    messageStore: MessageStore,
    notificationStore: NotificationStore,
    personalMessageStore: PersonalMessageStore,
    activeUsersStore: ActiveUserStore,
    taskStore: TaskStore,
    clientApi: ClientApi,
    clock: Clock
): ListenConductorEvents => async () => {

    let lastCheckedMessaging = clock.currentTime()

    eventWebSocket.start((message => {
        if (message && message.type === 'new-group-channel-created') {

            const oldChannels = channelStore.getChannels()

            const channel: GroupChannel = {
                type: message.channelType,
                id: message.id,
                name: message.name,
                createdAt: message.createdAt,
            }

            channelStore.setChannels([...oldChannels, channel])
        } else if (message && message.type === 'new-group-message') {
            messageStore.addMessage(
                message.channelId,
                {
                    type: 'group-channel-message',
                    id: message.id,
                    timestamp: message.timestamp,
                    message: message.message,
                    channelId: message.channelId,
                    from: message.from,
                    read: false,
                    savedToBackend: true
                }
            )
        } else if (message && message.type === 'new-general-notification') {
            notificationStore.addNotification({
                type: 'general-notification',
                id: message.id,
                timestamp: message.timestamp,
                message: message.message,
                from: message.from,
                read: false,
                savedToBackend: true
            })
        } else if (message && message.type === 'new-personal-message') {
            personalMessageStore.addMessages([{
                type: 'personal-message',
                messageId: message.id,
                personId: message.personId,
                timestamp: message.timestamp,
                message: message.message,
                from: message.from,
                read: false,
                savedToBackend: true
            }])
        }
    }))


    const fetchActiveUsers = async () => {
        const users = await clientApi.getActiveUsers()
        activeUsersStore.updateUsers(users.map((user) => toActiveUser(user)))
    }

    const fetchOpenTasks = async () => {
        const alarms = await clientApi.getAlarms()
        
        if (alarms !== undefined) {
            taskStore.saveTasks(alarms)
        }
    }


    const checkNewGroupMessages = async () => {
        const messages = await clientApi.getMessages(clock.currentTime() - 1000 * 60 * 60 * 2, clock.currentTime() + 1000 * 60 * 10)
        const oldMessages = messageStore.getMessages().value

        const newMessages = messages.filter((it) => {
            const existing = oldMessages.get(it.channelId)
            if (existing !== undefined) {
                return !existing.find((e) => e.id === it.id)
            } else {
                return false
            }
        })

        if (newMessages.length > 0) {
            messageStore.addMessagesMultiChannel(newMessages.map(message => { return { type: 'group-channel-message', ...message, read: false, savedToBackend: true } }))
        }
    }

    const checkNewChannels = async () => {
        const channels = await clientApi.getChatChannels(clock.currentTime() - 1000 * 60 * 60 * 2, clock.currentTime() + 1000 * 60 * 10)
        const old = channelStore.getChannels()

        const newChannels = channels.filter((it) => !old.find((o) => o.id === it.id))

        if (newChannels.length > 0) {
            channelStore.setChannels([...old, ...newChannels])    
        }        
    }

    const checkNewPersonalMessages = async () => {
        const personalMessages = await clientApi.getAllPersonalMessages(clock.currentTime() - 1000 * 60 * 60 * 2, clock.currentTime() + 1000 * 60 * 10)
        const oldMessages = personalMessageStore.getMessages().value
        
        const newMessages = personalMessages.filter((it) => {
            const existing = oldMessages.get(it.personId)
            if (existing !== undefined) {
                return !existing.find((e) => e.messageId === it.messageId)
            } else {
                return false
            }
        })

        if (newMessages.length > 0) {
            personalMessageStore.addMessages(newMessages.map(message => { return { type: 'personal-message', ...message, read: false, savedToBackend: true } }))        
        }
    }

    const checkMissedMessagingChanges = async () => {
        if (lastCheckedMessaging + 1000 * 60 * 2 < clock.currentTime()) {
            await checkNewChannels()
            await checkNewGroupMessages()
            await checkNewPersonalMessages()
            lastCheckedMessaging = clock.currentTime()
        }
    }

    of({}).pipe(
        tap(async () => {
            await Promise.all([fetchActiveUsers(), fetchOpenTasks(), checkMissedMessagingChanges()])
        }),
        delay(16000),
        repeat()
    ).subscribe()


    // TODO: every two minutes or so, fetch messages from past 10 minutes. If they contain new messages, add them to the message stores. filter duplicates somehow? or is there need?

    const fetchChannels = async () => {
        const channels = await clientApi.getChatChannels(clock.currentTime() - 1000 * 60 * 60 * 24 * 10, clock.currentTime() + 1000 * 60 * 10)
        const old = channelStore.getChannels()
        channelStore.setChannels([...old, ...channels])
    }

    const fetchGroupMessages = async () => {
        const messages = await clientApi.getMessages(clock.currentTime() - 1000 * 60 * 60 * 24 * 10, clock.currentTime() + 1000 * 60 * 10)
        messageStore.addMessagesMultiChannel(messages.map(message => { return { type: 'group-channel-message', ...message, read: true, savedToBackend: true } }))
    }

    const fetchPersonalMessages = async () => {
        const personalMessages = await clientApi.getAllPersonalMessages(clock.currentTime() - 1000 * 60 * 60 * 24 * 10, clock.currentTime() + 1000 * 60 * 10)
        personalMessageStore.addMessages(personalMessages.map(message => { return { type: 'personal-message', ...message, read: true, savedToBackend: true } }))
    }

    const fetchNotifications = async () => {
        const notifications = await clientApi.getGeneralNotifications()
        notificationStore.setNotifications(notifications.map(notification => { return { type: 'general-notification', ...notification, read: true, savedToBackend: true } }))
    }

    // TODO: risk of duplicates.. are there other concurrency issues?
    await Promise.all([fetchChannels(), fetchGroupMessages(), fetchPersonalMessages(), fetchNotifications()])
}



function convertToLocationDTO(location: ConductorLocation): LocationDTO | undefined {
    switch (location.type) {
      case 'unknown':
        return undefined
      case 'on-station':
        return {
          position: {
            station: location.station,
          },
        }
      case 'on-vehicle': {
        let position: Position | undefined = undefined
  
        switch (location.vehicleLocation.type) {
          case 'unknown': {
            position = undefined
            break
          }
          case 'on-station': {
            position = {
              station: location.vehicleLocation.station,
            }
            break
          }
          case 'between-stations': {
            position = {
              between: {
                from: location.vehicleLocation.from,
                to: location.vehicleLocation.to,
              },
            }
            break
          }
        }
  
        return {
          train: {
            line: location.line,
            vehicle: location.vehicle.toString(),
            trainDepartureDate: location.trainDepartureDate,
            trainNumber: location.trainNumber?.toString() ?? "-",
          },
          position: position,
        }
      }
    }
  }

// TODO: update code to handle new format for user data and location data
function toActiveUser(userData: UserData): ActiveUser {
    
    // TODO: it would be good to always show last known location of a user (unless a new work shift has been started after the last update). But this needs changes to times shown on UI. Ask if the UI table can be changed slightly.
    let location = convertToLocationDTO(userData.lastKnownLocation?.value ?? { type: 'unknown'})
    if ((userData.currentLocation?.timestamp ?? 0) > ((userData.lastKnownLocation?.timestamp ?? 0) + 1000 * 60 * 30)) {
        location = convertToLocationDTO(userData.currentLocation?.value ?? { type: 'unknown'})
    }

    return {
        id: userData.id,
        firstName: userData.firstName,
        lastName: userData.lastName,
        lastSeen: userData.lastSeen,
        state: {
            location: location === undefined ? undefined :  {
                value : location,
                timestamp: userData.lastKnownLocation?.timestamp ?? userData.lastSeen
            },
            status: {
                value: {
                    workshift: userData.status?.workShift ?? "-",
                    status: convertStatus(userData.status?.value)
                }
            }            
        }
    }
}

function convertStatus(workStatus: WorkStatus | undefined): string {
    if (workStatus === undefined) return 'OffDuty'
    switch (workStatus.type) {
        case 'off-duty': {
          return 'OffDuty'
        }
        case 'working': {
          return 'Working'        
        }
        case 'on-break': {
          return 'OnBreak'
        }
    }
}

