import { ConductorLocation, VehicleLocation } from "../domain/entities/Location"

export type GroupChannelMessageDTO = {
    id: string
    message: string
    channelId: string
    timestamp: number
    from: {
        name: string
        id: string
        type: 'conductor' | 'dispatcher'
    }
}

export type GeneralNotificationDTO = {
    id: string
    message: string
    timestamp: number
    from: {
        id: string
        name: string
    }
}

export type PersonalMessageDTO = {
    messageId: string
    personId: string
    message: string
    timestamp: number
    from: { type: 'dispatcher' | 'conductor', name: string, id: string }
}

export type GroupChannelDTO = {
    type: 'work-shift' | 'custom'
    id: string
    name: string
    createdAt: number
}

export type ParticipantDTO = {
    id: string
    name: string
    joinedAt: number
    leftAt?: number
}

export type DispatcherTaskDTO = {
    id: string
    created: number
    done?: number
    createdBy: {
        id: string
        firstName: string
        lastName: string
    }
    event: {
        location: LocationDTO
    }
    status: string
    t: string
}


export type LocationDTO = {
    train?: Train
    position?: Position
}

export type Train = {
    line?: string,
    vehicle?: string,
    trainNumber?: string,
    trainDepartureDate?: string
}

export type BetweenStations = {
    from: string,
    to: string
}

export type Position = {
    station?: string,
    between?: BetweenStations
}


export type UserData = {
    id: string
    firstName: string
    lastName: string
    lastSeen: number
    status: Status | undefined
    currentLocation: Location | undefined
    lastKnownLocation: Location | undefined
  }
  
  export type Location = {
    value: ConductorLocation
    timestamp: number
  }
  
  export type Status = {
    value: WorkStatus
    updatedAt: number
    workShift: string
  }
  
  export type WorkStatus =
  | {
      type: 'off-duty'
    }
  | {
      type: 'on-break'
    }
  | {
      type: 'working'
      category?:
        | 'hsl-train-and-platform-work'
        | 'vr-commuter-traffic-work'
        | 'stand-by-duty'
        | 'removing-ice-and-snow'
        | 'other-requested-hsl-work' // NOTE: will become mandatory eventually
    }


export function convertLocation(location: LocationDTO | undefined): ConductorLocation {
    if (location?.position?.station !== undefined && location?.train?.vehicle === undefined) {
        return {
            type: 'on-station',
            station: location.position.station
        }
    } else if (location?.train?.vehicle !== undefined) {
        return {
            type: 'on-vehicle',
            vehicle: Number(location.train.vehicle),
            trainNumber: location.train.trainNumber ? Number(location.train.trainNumber) : undefined,
            trainDepartureDate: location.train.trainDepartureDate,
            line: location.train.line,
            vehicleLocation: toVehicleLocation(location.position)
        }
    } else {
        return { type: 'unknown' }
    }
}

function toVehicleLocation(position: any): VehicleLocation {
    if (position === undefined) {
        return { type: 'unknown' }
    } else if (position.station !== undefined) {
        return { type: 'on-station', station: position.station }
    } else if (position.between !== undefined) {
        return { type: 'between-stations', from: position.between.from, to: position.between.to }
    } else {
        return { type: 'unknown' }
    }
}