import { BehaviorSubject, Observable, defer, Subject } from "rxjs";
import { map, retryWhen, delay, take } from "rxjs/operators";
import { combineLatest } from 'rxjs';
import { AuthResponse } from "../network/ClientApi";
import { PersonalMessage } from "../domain/entities/PersonalMessage";
import { PersonalChannel } from "./ObserveChannels";

interface PersonalMessageStore {
    getMessages: () => BehaviorSubject<Map<String, PersonalMessage[]>>
    markAllAsRead: (userId: string) => void
}

interface ClientApi {
    sendPersonalMessage: (userId: string, message: string) => Promise<void>
}

interface PersistentStorage {
    getAuthDetails: () => AuthResponse | undefined
}

export type PersonalChannelState = {
    type: 'personal-channel-loaded',
    state: {
        channel: PersonalChannel
        messages: PersonalMessage[]
    }
    actions: {
        sendMessage: (message: string) => void
    }
}

export type StatusNotification = 'sending-message-failed'

export type ChatInPersonalChannel = (personId: string) => Observable<PersonalChannelState>

export const createChatInPersonalChannelUseCase = (messageStore: PersonalMessageStore, clientApi: ClientApi, persistentStorage: PersistentStorage): ChatInPersonalChannel => {

    let pendingMessageList = new BehaviorSubject<PersonalMessage[]>([])

    let statusNotifications = new Subject<StatusNotification>()

    function sendMessage(personId: string, message: string) {
        const authDetails = persistentStorage.getAuthDetails()!
        const existingPending = pendingMessageList.value

        pendingMessageList.next([...existingPending, {
            type: 'personal-message',
            messageId: 'pending-' + Date.now(),
            personId,
            message,
            from: {
                name: authDetails.user.firstName + ' ' + authDetails.user.lastName,
                id: authDetails.user.id,
                type: 'dispatcher'
            },
            timestamp: Date.now(),
            read: true,
            savedToBackend: false
        }])

        defer(() => { return clientApi.sendPersonalMessage(personId, message) })
            .pipe(
                retryWhen(errors => errors.pipe(delay(7000), take(3)))
            )
            .subscribe(
                data => { },
                _err => {
                    statusNotifications.next('sending-message-failed')
                }
            )
    }

    return (personId) => {

        const channel: PersonalChannel = { personId }

        let state: Observable<PersonalChannelState> = combineLatest(
            messageStore.getMessages(),
            pendingMessageList
        )
            .pipe(map(val => {
                const [messagesGroupedByPerson, pendingMessages] = val

                const channelMessages = messagesGroupedByPerson.get(personId) ?? []
                const pending = pendingMessages.filter(message => channelMessages.find(m => m.message === message.message && m.read === false) === undefined)

                if (pendingMessages.length > pending.length) {
                    pendingMessageList.next(pending)
                }

                const sortedMessages = [...channelMessages, ...pending].sort((a, b) => a.timestamp - b.timestamp)

                if (sortedMessages.find(m => m.read === false)) {
                    messageStore.markAllAsRead(personId)
                }

                return {
                    type: 'personal-channel-loaded',
                    state: {
                        channel,
                        messages: sortedMessages
                    },
                    actions: {
                        sendMessage: (message) => sendMessage(personId, message)
                    }
                }
            }))

        return state
    }
} 