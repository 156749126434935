import React, { useState, useContext, useEffect } from 'react';
import { Button, Dialog, DialogTitle, DialogContent, TextField, DialogActions } from '@material-ui/core';
import { DomainContext } from '../../../DomainContext';
import Autocomplete from '@material-ui/lab/Autocomplete';
import { ActiveUser } from '../../../domain/entities/ActiveUser';

export type Props = {
    onClose: () => void
    onSubmit: (id: string) => void
}

export const StartPersonalConversationDialog = (props: Props) => {
    const [users, setUsers] = useState<ActiveUser[]>([])

    const { activeUsersStore } = useContext(DomainContext)

    useEffect(() => {
        setUsers(activeUsersStore.getActiveUsers())
    }, [activeUsersStore])

    return (
        <Dialog open={true} onClose={() => { }} aria-labelledby="form-dialog-title">
            <DialogTitle id="form-dialog-title">Valitse konduktööri</DialogTitle>

            <DialogContent>
                <Autocomplete
                    fullWidth={true}
                    noOptionsText="Ei henkilöitä"
                    id="combo-box-demo"
                    options={users}
                    getOptionLabel={(option) => option.firstName + " " + option.lastName + " (" + option.id + ")"}
                    style={{ width: 300 }}
                    onChange={(event: any, newValue: ActiveUser | null) => {
                        if (newValue !== null) {
                            props.onSubmit(newValue.id)
                        }
                    }}
                    renderInput={(params) => <TextField {...params} label="Konduktööri" variant="outlined" />}
                />
            </DialogContent>

            <DialogActions>
                <Button onClick={props.onClose} color="primary">
                    Peruuta
                </Button>
            </DialogActions>
        </Dialog>
    )
}
