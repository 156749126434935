import React, { useState, useContext, useEffect } from 'react';
import { Button, Dialog, DialogTitle, DialogContent, TextField, DialogActions } from '@material-ui/core';
import { DomainContext } from '../../../DomainContext';
import Autocomplete from '@material-ui/lab/Autocomplete';
import { GroupChannel } from '../../../domain/entities/GroupChannel';
import moment from 'moment-timezone';

export type Props = {
    onClose: () => void
    onSelectGroupChannel: (id: string) => void
}

type Item = {
    text: string
    channelId: string
}

export const SearchDialog = (props: Props) => {
    const [items, setItems] = useState<Item[]>([])
    const { activeUsersStore, channelStore, groupMessageStore } = useContext(DomainContext)


    function toChannelName(channel: GroupChannel): string {
        switch (channel.type) {
            case 'work-shift': return "Työvuoro " + channel.name
            default: return channel.name
        }
    }

    useEffect(() => {
        const channels = channelStore.getChannels()

        let items: Item[] = []

        let channelMap: Map<string, string> = new Map()

        channels.forEach(c => {
            const name = toChannelName(c)
            channelMap.set(c.id, name)
            items.push({ text: name + ' (' + moment(c.createdAt).tz("Europe/Helsinki").format("DD.MM.") + ')', channelId: c.id })
        })

        const messages = groupMessageStore.getMessages().value

        messages.forEach(foo => {

            foo.forEach(message => {
                const text = message.message + ' (' + moment(message.timestamp).tz("Europe/Helsinki").format("DD.MM. HH:mm") + ') - ' + channelMap.get(message.channelId) ?? ""
                items.push({ text: text, channelId: message.channelId })
            })

        })

        setItems(items)
    }, [activeUsersStore, channelStore, groupMessageStore])

    return (
        <Dialog fullWidth maxWidth="md" open={true} onClose={() => { }} aria-labelledby="form-dialog-title">
            <DialogTitle id="form-dialog-title">Haku</DialogTitle>

            <DialogContent>
                <Autocomplete
                    fullWidth={true}
                    noOptionsText="Ei tuloksia"
                    id="search-dialog"
                    options={items}
                    getOptionLabel={(option) => option.text}

                    onChange={(event: any, newValue: Item | null) => {
                        if (newValue !== null) {
                            props.onSelectGroupChannel(newValue.channelId)
                        }
                    }}
                    renderInput={(params) => <TextField {...params} label="Hakutermi" variant="outlined" />}
                />
            </DialogContent>

            <DialogActions>
                <Button onClick={props.onClose} color="primary">
                    Peruuta
                </Button>
            </DialogActions>
        </Dialog>
    )
}
