import React, { useContext, useEffect, useState } from 'react';
import clsx from 'clsx';
import { Link } from 'react-router-dom';
import { AppBar, Toolbar, IconButton, Typography, Drawer, Divider, List, ListItem, ListItemIcon, ListItemText, Theme, createStyles, useTheme, Badge, Menu, MenuItem } from '@material-ui/core';
import MenuIcon from '@material-ui/icons/MenuOutlined';
import VRLogoLarge from '../../images/kota_logo.png';
import { makeStyles } from "@material-ui/styles";
import ChevronLeftIcon from '@material-ui/icons/ChevronLeft';
import ChevronRightIcon from '@material-ui/icons/ChevronRight';
import GroupIcon from '@material-ui/icons/Group';
import ChatIcon from '@material-ui/icons/Chat';
import NotificationsIcon from '@material-ui/icons/NotificationsOutlined';
import { AccountCircle } from '@material-ui/icons';
import { DomainContext } from '../../DomainContext';
import { EmergencyAlarmState } from '../../usecases/ShowEmergencyAlarms';
import moment from 'moment';
import "moment/locale/fi";
import { map } from 'rxjs/operators';

interface DrawerProps {
    path: string | string[] | undefined
}

const drawerWidth = 220;

const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        root: {
            display: 'flex',
        },
        appBar: {
            zIndex: theme.zIndex.drawer + 1,
            transition: theme.transitions.create(['width', 'margin'], {
                easing: theme.transitions.easing.sharp,
                duration: theme.transitions.duration.leavingScreen,
            }),
        },
        appBarShift: {
            marginLeft: drawerWidth,
            width: `calc(100% - ${drawerWidth}px)`,
            transition: theme.transitions.create(['width', 'margin'], {
                easing: theme.transitions.easing.sharp,
                duration: theme.transitions.duration.enteringScreen,
            }),
        },
        menuButton: {
            marginRight: 36,
        },
        hide: {
            display: 'none',
        },
        drawer: {
            width: drawerWidth,
            flexShrink: 0,
            whiteSpace: 'nowrap'
        },
        drawerOpen: {
            width: drawerWidth,
            transition: theme.transitions.create('width', {
                easing: theme.transitions.easing.sharp,
                duration: theme.transitions.duration.enteringScreen,
            }),
        },
        drawerClose: {
            transition: theme.transitions.create('width', {
                easing: theme.transitions.easing.sharp,
                duration: theme.transitions.duration.leavingScreen,
            }),
            overflowX: 'hidden',
            width: theme.spacing(7) + 1,
            [theme.breakpoints.up('sm')]: {
                width: theme.spacing(9) + 1,
            },
        },
        toolbar: {
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'flex-end',
            padding: theme.spacing(0, 1),
            // necessary for content to be below app bar
            ...theme.mixins.toolbar,
        },
        content: {
            flexGrow: 1,
            height: '100vh',
            padding: theme.spacing(0),
        },
        title: {
            flexGrow: 1,
        },
        version: {
            color: '#AAAAAA',
            padding: '0 20px'
        }
    }),
)

export const KotaDrawer: React.FunctionComponent<DrawerProps> = (props) => {
    const classes = useStyles()
    const theme = useTheme()
    const [open, setOpen] = useState(false)

    const [unreadMessages, setUnreadMessages] = useState(0)

    const { showEmergencyAlarmsModel, logout, observeChannels } = useContext(DomainContext)

    const [alarms, setAlarms] = useState<EmergencyAlarmState>({ alarms: [], displayedAlarm: undefined, numberOfOpenAlarms: 0 })

    const [alarmAnchorEl, setAlarmAnchorEl] = useState<null | HTMLElement>(null)
    const [userAnchorEl, setUserAnchorEl] = useState<null | HTMLElement>(null)

    const isAlarmsMenuOpen = Boolean(alarmAnchorEl)
    const isUserMenuOpen = Boolean(userAnchorEl)

    const handleAlarmsMenuClose = () => {
        setAlarmAnchorEl(null)
    }

    const handleUserMenuClose = () => {
        setUserAnchorEl(null)
    }

    const handleAlarmMenuOpen = (event: React.MouseEvent<HTMLElement>) => {
        if (alarms.alarms.length > 0) {
            setAlarmAnchorEl(event.currentTarget)
        }
    }

    const handleUserMenuOpen = (event: React.MouseEvent<HTMLElement>) => {
        setUserAnchorEl(event.currentTarget);
    }

    useEffect(() => {
        const subscription = showEmergencyAlarmsModel.state.subscribe(setAlarms)
        return function cleanup() {
            subscription.unsubscribe()
        }
    }, [showEmergencyAlarmsModel])

    useEffect(() => {
        const subscription = observeChannels().pipe(map(it => it.type === 'loaded' ? it.numberOfUnreadMessages : 0)).subscribe(setUnreadMessages)
        return function cleanup() {
            subscription.unsubscribe()
        }
    }, [observeChannels])

    const handleDrawerOpen = () => {
        setOpen(true)
    }

    const handleDrawerClose = () => {
        setOpen(false)
    }

    const titles = (path: String | string[] | undefined) => {
        switch (path) {
            case "/": return "Konduktöörit"
            case "/viestit": return "Viestit"
            default: return ""
        }
    }

    const imgStyle = {
        height: 35,
        marginRight: 10,
        marginLeft: -15
    }

    const renderAlarmsMenu = (
        <Menu
            anchorEl={alarmAnchorEl}
            anchorOrigin={{ vertical: 'top', horizontal: 'right' }}
            id={'alarms-menu'}
            keepMounted
            transformOrigin={{ vertical: 'top', horizontal: 'right' }}
            open={isAlarmsMenuOpen}
            onClose={handleAlarmsMenuClose}
        >
            {alarms.alarms.map(alarm => {
                if (alarm.status === 'open') {
                    const text = "Hälytys - " + moment(alarm.alarmStarted).format("YYYY-MM-DD HH:mm")
                    return (<MenuItem onClick={() => { showEmergencyAlarmsModel.actions.displayAlarm(alarm.id) }}>{text}</MenuItem>)
                } else {
                    return (<></>)
                }
            })}
        </Menu>
    )

    const renderUserMenu = (
        <Menu
            anchorEl={userAnchorEl}
            anchorOrigin={{ vertical: 'top', horizontal: 'right' }}
            id={'user-menu'}
            transformOrigin={{ vertical: 'top', horizontal: 'right' }}
            open={isUserMenuOpen}
            onClose={handleUserMenuClose}
        >
            <MenuItem onClick={logout}>Kirjaudu ulos</MenuItem>
        </Menu>
    )

    return (
        <div className={classes.root}>
            <AppBar color="secondary" position="fixed" className={clsx(classes.appBar, { [classes.appBarShift]: open })}>
                <Toolbar>
                    <IconButton
                        color="primary"
                        aria-label="open drawer"
                        onClick={handleDrawerOpen}
                        edge="start"
                        className={clsx(classes.menuButton, { [classes.hide]: open })}>
                        <MenuIcon />
                    </IconButton>

                    <img src={VRLogoLarge} style={imgStyle} alt={"VR logo"} />
                    <Typography variant="h6" color="inherit" noWrap className={classes.title}>
                        {'KOTA > ' + titles(props.path)}
                    </Typography>

                    <Typography variant="caption" color="inherit" noWrap className={classes.version}>
                        versio 2.1.1
                    </Typography>
                    <IconButton color="inherit" onClick={handleAlarmMenuOpen}>
                        <Badge badgeContent={alarms.numberOfOpenAlarms} color="error">
                            <NotificationsIcon />
                        </Badge>
                    </IconButton>
                    <IconButton color="inherit" onClick={handleUserMenuOpen}>
                        <AccountCircle />
                    </IconButton>
                </Toolbar>
            </AppBar>
            {renderAlarmsMenu}
            {renderUserMenu}
            <Drawer
                variant="permanent"
                className={clsx(classes.drawer, {
                    [classes.drawerOpen]: open,
                    [classes.drawerClose]: !open,
                })}
                classes={{
                    paper: clsx({
                        [classes.drawerOpen]: open,
                        [classes.drawerClose]: !open,
                    }),
                }}
            >
                <div className={classes.toolbar}>
                    <IconButton onClick={handleDrawerClose}>
                        {theme.direction === 'rtl' ? <ChevronRightIcon /> : <ChevronLeftIcon />}
                    </IconButton>
                </div>

                <Divider />

                <List >
                    <ListItem button key="Konduktöörit" component={Link} to="/" >
                        <ListItemIcon >
                            <GroupIcon />
                        </ListItemIcon>
                        <ListItemText primary="Konduktöörit" />
                    </ListItem>
                    <ListItem button component={Link} to="/viestit" >
                        <ListItemIcon>
                            <Badge badgeContent={unreadMessages} color="error">
                                <ChatIcon />
                            </Badge>
                        </ListItemIcon>
                        <ListItemText primary="Viestit" />
                    </ListItem>

                </List>
            </Drawer>
            <main className={classes.content}>
                <div className={classes.toolbar} />
                {props.children}
            </main>
        </div>
    )
}