import { BehaviorSubject } from "rxjs"
import { PersonalMessage } from "../domain/entities/PersonalMessage"

export class PersonalMessageStore {

    private messages: Map<String, PersonalMessage[]> = new Map()

    private messagesSubject = new BehaviorSubject<Map<String, PersonalMessage[]>>(this.messages)

    addMessage(message: PersonalMessage) {
        const oldMessages = this.messages.get(message.personId) ?? []
        this.messages.set(message.personId, [...oldMessages, message])
        this.messagesSubject.next(this.messages)
    }

    // TODO: not very efficient. optimize.
    addMessages(messages: PersonalMessage[]) {
        messages.forEach(message => {
            const old = this.messages.get(message.personId) ?? []
            this.messages.set(message.personId, [...old, message])
        })
        this.messagesSubject.next(this.messages)
    }

    getMessages(): BehaviorSubject<Map<String, PersonalMessage[]>> {
        return this.messagesSubject
    }

    markAllAsRead(userId: string) {
        const personalMessages = this.messages.get(userId)
        if (personalMessages) {
            const readMessages: PersonalMessage[] = personalMessages.map(message => {
                return { ...message, read: true }
            })
            this.messages.set(userId, readMessages)
        }
        this.messagesSubject.next(this.messages)
    }

    markAllMessagesAsRead() {
        this.messages.forEach((value, key) => {
            value.forEach((v, k) => v.read = true)
        })
        this.messagesSubject.next(this.messages)
    }
}