import React from 'react';
import Button from '@material-ui/core/Button';
import { makeStyles, Theme, Grid, } from '@material-ui/core';
import config from '../../config';

const useStyles = makeStyles((theme: Theme) => ({
    title: {
        marginTop: '100px'
    },
    text: {
        marginTop: '30px'
    },
    button: {
        marginTop: '10px'
    }
}));

export const LoginExpiredPage = () => {
    const classes = useStyles()
    const login = async () => window.location.replace(config.loginPageUrl)

    return (
        <Grid container spacing={3} alignContent="stretch" justify="center"
            alignItems="center" direction="column">

            <h2 className={classes.title} >VR KOTA</h2>
            <p className={classes.text} >Kirjautuminen vanhentui. Ole hyvä ja kirjaudu uudestaan.</p>

            <Button variant="contained" color="primary" onClick={login} className={classes.button}>
                Kirjaudu AD-tunnuksella
            </Button>
        </Grid>
    );
}