import React, { useState, useContext, useEffect } from 'react';
import { WriteMessageComponent } from './components/WriteMessageComponent';
import { DomainContext } from '../../DomainContext';
import { GroupChannel } from '../../domain/entities/GroupChannel';
import { MessagingState, createMessagingViewModel } from './MessagingViewModel';
import { SideBar } from './components/SideBar';
import { ChannelHeader } from './components/ChannelHeader';
import moment from 'moment-timezone';
import { MessageList } from './components/MessageList';
import { NewChannelDialog } from './components/NewChannelDialog';
import { StartPersonalConversationDialog } from './components/StartPersonalConversationDialog';
import { LatestMessagesList } from './components/LatestMessagesList';
import { Button } from '@material-ui/core';
import { SearchDialog } from './components/SearchDialog';

export const MessagingPage = () => {
    const [state, setState] = useState<MessagingState>({ type: 'loading' })

    const { observeChannels, chatInGroupChannel, chatInPersonalChannel, sendNotifications, createNewChannel, showLatestMessages } = useContext(DomainContext)

    useEffect(() => {
        const viewModel = createMessagingViewModel(
            observeChannels,
            chatInGroupChannel,
            chatInPersonalChannel,
            sendNotifications,
            createNewChannel,
            showLatestMessages
        )
        const subscription = viewModel().subscribe(setState)

        return function cleanup() {
            subscription.unsubscribe()
        }
    }, [observeChannels, chatInGroupChannel, chatInPersonalChannel, sendNotifications, createNewChannel, showLatestMessages])

    const content = () => {
        if (state.type === 'loading') {
            return <p>Ladataan</p>
        }

        const sidebar = <SideBar
            selectedChannel={determineSelectedChannel(state)}
            state={state.sideBarState}
        />

        let dialog = <></>

        switch (state.dialogState?.type) {
            case undefined: {
                dialog = <></>
                break
            }
            case 'create-new-channel': {
                dialog = <NewChannelDialog state={state.dialogState.state} />
                break
            }
            case 'start-personal-conversation': {
                dialog = <StartPersonalConversationDialog onClose={state.dialogState.onCancelled} onSubmit={state.dialogState.onPersonSelected} />
                break
            }
            case 'search': {
                dialog = <SearchDialog onClose={state.dialogState.onClose} onSelectGroupChannel={state.dialogState.onSelectGroupChannel} />
                break
            }
        }

        switch (state.type) {
            case 'no-channel-selected': return (<>{sidebar}{dialog}</>)
            case 'group-channel-selected': return (
                <>
                    {sidebar}
                    {dialog}
                    <div style={{ width: '100%', height: `calc(100% - 100px)` }}>
                        <ChannelHeader channelName={groupChannelName(state.channel)} subtitle={moment(state.channel.createdAt).tz("Europe/Helsinki").format("DD.MM.YYYY")} />
                        <MessageList messages={state.messages} />
                        <WriteMessageComponent onSend={(message: string) => state.actions.sendMessage(message)} />
                    </div>
                </>
            )
            case 'personal-channel-selected': return (
                <>
                    {sidebar}
                    {dialog}
                    <div style={{ width: '100%', height: `calc(100% - 100px)` }}>
                        <ChannelHeader channelName={"Henkilö: " + (state.channel.personName ?? "") + " (" + state.channel.personId + ")"} subtitle={''} />
                        <MessageList messages={state.messages} />
                        <WriteMessageComponent onSend={(message: string) => state.actions.sendMessage(message)} />
                    </div>
                </>
            )
            case 'general-notifications-selected': return (
                <>
                    {sidebar}
                    {dialog}
                    <div style={{ width: '100%', height: `calc(100% - 100px)` }}>
                        <ChannelHeader channelName={'Ilmoitukset'} subtitle={''} />
                        <MessageList messages={state.notifications} />
                        <WriteMessageComponent onSend={(message: string) => state.actions.sendMessage(message)} />
                    </div>
                </>
            )
            case 'show-latest-messages': return (
                <>
                    {sidebar}
                    {dialog}
                    <div style={{ width: '100%', height: `calc(100% - 100px)` }}>
                        <ChannelHeader channelName={'Viimeisimmät viestit'} subtitle={''} />
                        <LatestMessagesList messages={state.messages} />
                        <div style={{ width: '100%', height: `100px` }}>
                            <Button style={{ marginTop: 40, marginLeft: 40 }} variant="contained" color="primary" onClick={() => state.actions.markAllAsRead()}>
                                Merkitse kaikki luetuiksi
                            </Button>
                        </div>
                    </div>
                </>
            )
        }
    }

    return (
        <div style={{ maxWidth: '100%', height: `calc(100% - 60px)`, display: 'flex' }}>
            {content()}
        </div>
    )
}

function groupChannelName(channel: GroupChannel): string {
    switch (channel.type) {
        case 'work-shift': return 'Työvuoro ' + channel.name
        case 'custom': return channel.name
    }
}

function determineSelectedChannel(state: MessagingState): { type: 'group' | 'personal', id: string } | undefined {
    switch (state.type) {
        case 'loading': return undefined
        case 'group-channel-selected': return { type: 'group', id: state.channel.id }
        case 'no-channel-selected': return undefined
    }
}