import { Button, createStyles, Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle, makeStyles, Theme } from '@material-ui/core';
import Alert from '@material-ui/lab/Alert/Alert';
import React, { useState, useContext, useEffect } from 'react';
import CloseIcon from '@material-ui/icons/Close';
import IconButton from '@material-ui/core/IconButton';
import { DomainContext } from '../../DomainContext';
import { EmergencyAlarmState } from '../../usecases/ShowEmergencyAlarms';
import { ConductorLocation } from '../../domain/entities/Location';

const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        root: {
            margin: 0,
            padding: theme.spacing(2),
        },
        closeButton: {
            position: 'absolute',
            right: theme.spacing(1),
            top: theme.spacing(1),
            color: theme.palette.grey[500],
        },
    })
)

export const AlarmDialog = () => {
    const classes = useStyles()
    const { showEmergencyAlarmsModel } = useContext(DomainContext)
    const [alarms, setAlarms] = useState<EmergencyAlarmState>({ alarms: [], displayedAlarm: undefined, numberOfOpenAlarms: 0 })

    useEffect(() => {
        const subscription = showEmergencyAlarmsModel.state.subscribe(setAlarms)
        return function cleanup() {
            subscription.unsubscribe()
        }
    }, [showEmergencyAlarmsModel])

    if (alarms.displayedAlarm) {
        return (
            <Dialog fullWidth maxWidth="sm" open={true} onClose={() => { }} aria-labelledby="form-dialog-title">
                <DialogTitle id="form-dialog-title">Hälytys</DialogTitle>

                <IconButton aria-label="close" className={classes.closeButton} onClick={() => { showEmergencyAlarmsModel.actions.closeCurrentDialog() }}>
                    <CloseIcon />
                </IconButton>

                <DialogContent>
                    <Alert variant="filled" severity="error">
                        Konduktööri on laukaissut hälytyksen painamalla hätänappia.
                </Alert>

                    <br />

                    <DialogContentText>
                        {alarms.displayedAlarm.name + " (" + alarms.displayedAlarm.personId + ")"}
                    </DialogContentText>
                    <DialogContentText>
                        Sijainti hälytyksen alkaessa: {convertLocation(alarms.displayedAlarm.initialLocation)}
                    </DialogContentText>

                    <DialogContentText>
                        Nykyinen sijainti: {convertLocation(alarms.displayedAlarm.currentLocation)}
                    </DialogContentText>

                </DialogContent>
                <DialogActions>
                    <Button onClick={() => { showEmergencyAlarmsModel.actions.markAlarmAsDone(alarms.displayedAlarm!.id) }} color="primary">
                        Merkitse tilanne käsitellyksi
            </Button>
                </DialogActions>
            </Dialog>
        )
    } else {
        return <></>
    }
}

function convertLocation(location: ConductorLocation): string {
    switch (location.type) {
        case 'unknown': return "Tuntematon"
        case 'on-station': return "Asema " + location.station
        case 'on-vehicle': return "Runko " + location.vehicle + " | Juna: " + (location.trainNumber ?? "-") + " | Linja: " + (location.line ?? "-")
    }
}