import React from 'react';
import { ListChildComponentProps } from "react-window";
import { GroupChannel } from "../../../domain/entities/GroupChannel";
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import ExpandLessIcon from '@material-ui/icons/ExpandLess';
import AddIcon from '@material-ui/icons/Add';
import MailIcon from '@material-ui/icons/Mail';
import { Avatar, Badge } from '@material-ui/core';
import ListItem from '@material-ui/core/ListItem';
import ListItemText from '@material-ui/core/ListItemText';
import moment from 'moment-timezone';
import { PersonalChannel } from '../../../usecases/ObserveChannels';

export type Item = CategoryTitle | WorkShiftChannel | CustomChannel | Personal

export type CategoryTitle = {
    type: 'category'
    name: string
    open: boolean
    numberOfMessages?: number
    toggle: () => void
    action: (() => void) | undefined
}

export type WorkShiftChannel = {
    type: 'work-shift-channel'
    channel: GroupChannel
    select: () => void
}

export type CustomChannel = {
    type: 'custom-channel'
    channel: GroupChannel
    select: () => void
}

export type Personal = {
    type: 'personal-channel'
    channel: PersonalChannel
    select: () => void
}

export const ChannelListRow = (items: Item[]) => (props: ListChildComponentProps) => {
    const { index, style } = props;

    const item = items[index]

    switch (item.type) {
        case 'category': {
            const icon = item.open ? <ExpandMoreIcon /> : <ExpandLessIcon />
            const action = item.action

            let actionIcon: any = null

            if (action !== undefined) {
                actionIcon = <AddIcon onClick={(e) => {
                    action()
                    e.stopPropagation()
                    e.preventDefault()
                }} />
            } else if (item.numberOfMessages) {
                actionIcon = (<Badge badgeContent={item.numberOfMessages} color="error">
                    <MailIcon fontSize="small" />
                </Badge>)
            }

            return (
                <ListItem button style={style} key={index} onClick={item.toggle} >
                    {icon}
                    <ListItemText style={{ marginLeft: 4 }} primary={item.name} />
                    {actionIcon}
                </ListItem>
            )
        }
        case 'work-shift-channel': {
            const prefix = '# Työvuoro '
            const postfix = ' (' + moment(item.channel.createdAt).tz("Europe/Helsinki").format("DD.MM.") + ')'
            return (
                <ListItem button style={style} key={index} onClick={item.select} >
                    <ListItemText style={{ marginLeft: 28 }} primary={prefix + item.channel.name + postfix} />
                    { (item.channel.numberOfUnreadMessages && item.channel.numberOfUnreadMessages > 0) ?
                        <Avatar variant="rounded" style={{ width: 20, height: 20, fontSize: '14px', backgroundColor: '#E85430' }}>{item.channel.numberOfUnreadMessages}</Avatar> : <></>
                    }
                </ListItem>
            )
        }
        case 'custom-channel': {
            const prefix = '# '
            const postfix = ' (' + moment(item.channel.createdAt).tz("Europe/Helsinki").format("DD.MM.") + ')'
            return (
                <ListItem button style={style} key={index} onClick={item.select} >
                    <ListItemText style={{ marginLeft: 28 }} primary={prefix + item.channel.name + postfix} />
                    { (item.channel.numberOfUnreadMessages && item.channel.numberOfUnreadMessages > 0) ?
                        <Avatar variant="rounded" style={{ width: 20, height: 20, fontSize: '14px', backgroundColor: '#E85430' }}>{item.channel.numberOfUnreadMessages}</Avatar> : <></>
                    }
                </ListItem>
            )
        }
        case 'personal-channel': {
            let name = '# ' + item.channel.personId

            if (item.channel.personName) {
                name = '# ' + item.channel.personName + ' (' + item.channel.personId + ')'
            }
            return (
                <ListItem button style={style} key={index} onClick={item.select} >
                    <ListItemText style={{ marginLeft: 28 }} primary={name} />
                    { (item.channel.numberOfUnreadMessages && item.channel.numberOfUnreadMessages > 0) ?
                        <Avatar variant="rounded" style={{ width: 20, height: 20, fontSize: '14px', backgroundColor: '#E85430' }}>{item.channel.numberOfUnreadMessages}</Avatar> : <></>
                    }
                </ListItem>
            )
        }
    }
}