import React, { useEffect, useRef } from "react"
import { makeStyles, createStyles, Theme, ListItem, ListItemText, List, Avatar, ListItemAvatar } from "@material-ui/core"
import AccountBoxIcon from '@material-ui/icons/AccountBox';
import moment from 'moment-timezone';
import { MessageInfo } from "../../../usecases/ShowLatestMessages";

const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        root: {
            height: `calc(100% - 75px)`,
            maxHeight: `calc(100% - 75px)`,
            width: '100%',
            position: 'relative',
            overflow: 'auto',
            backgroundColor: theme.palette.background.paper
        },
        inline: {
            display: 'inline',
        },
        icon: {
            '& svg': { fontSize: 150 }
        },
        green: {
            color: "#FFFFFF",
            backgroundColor: "#00B451",
        },
        blue: {
            color: theme.palette.getContrastText("#244496"),
            backgroundColor: "#244496",
        },
    }),
)

type Props = {
    messages: MessageInfo[]
}

export const LatestMessagesList = (props: Props) => {
    const classes = useStyles()

    const messagesEndRef = useRef<HTMLDivElement>(null)

    const scrollToBottom = () => {
        messagesEndRef.current?.scrollIntoView({ behavior: 'auto' })
    }

    useEffect(() => {
        scrollToBottom()
    }, [messagesEndRef, props])

    return (
        <List className={classes.root}>
            {props.messages.map((message) => (<LatestMessageElement key={message.message.timestamp} message={message} />))}
            <div ref={messagesEndRef} />
        </List>
    )
}

export const LatestMessageElement = (props: { message: MessageInfo }) => {
    const classes = useStyles();

    let prefix = ''

    switch (props.message.type) {
        case 'group-message': {
            if (props.message.channel?.type === 'work-shift') {
                prefix = '[Työvuoro ' + props.message.channel?.name + '] - '
            } else {
                prefix = '[' + props.message.channel?.name + '] - '
            }
            break
        }
        case 'personal-message': {
            prefix = '[Henkilökohtainen viesti] - '
            break
        }
        case 'notification': {
            prefix = '[Ilmoitus] - '
            break
        }
    }

    const time = moment(props.message.message.timestamp).tz("Europe/Helsinki").format("HH:mm")

    let name = props.message.message.from.name
    let avatar: any = (<Avatar className={classes.green}><AccountBoxIcon /></Avatar>)

    if (props.message.message.type === 'general-notification' || props.message.message.from.type === 'dispatcher') {
        name = "Ohjaus (" + name + ")"
        avatar = (<Avatar className={classes.blue}>OH</Avatar>)
    }

    return (
        <ListItem key={props.message.message.timestamp} style={props.message.message.savedToBackend ? {} : { backgroundColor: '#EAEAEA' }}>
            <ListItemAvatar>
                {avatar}
            </ListItemAvatar>
            <ListItemText style={{ whiteSpace: 'pre-line' }} primary={prefix + name + " - " + time} secondary={props.message.message.message} />
        </ListItem>
    )
}