import React, { useState } from 'react';
import { makeStyles, TextField, InputAdornment } from '@material-ui/core';
import SendIcon from '@material-ui/icons/Send';

const useStyles = makeStyles((theme) => ({
    text: {
        margin: theme.spacing(1),
        width: 'calc(100% - 20px)',
    },
    button: {
        margin: theme.spacing(1),
        height: '85%'
    },
}));

export type WriteMessageProps = {
    onSend: (message: string) => void
}

export const WriteMessageComponent = (props: WriteMessageProps) => {

    const [message, setMessage] = useState<string | undefined>(undefined)

    const handleInputChange = (event: any) => {
        setMessage(event.target.value)
    }

    const classes = useStyles();

    return (
        <div style={{ width: '100%', height: `100px` }}>
            <TextField
                className={classes.text}
                value={message}
                onChange={handleInputChange}
                fullWidth
                multiline={true}
                id="outlined-basic"
                label="Kirjoita uusi viesti"
                variant="outlined"
                InputProps={{
                    endAdornment: (
                        <InputAdornment position='end'>
                            <SendIcon color='primary' onClick={() => {
                                if (message !== undefined && message !== "") {
                                    setMessage("")
                                    props.onSend(message)
                                }
                            }} />
                        </InputAdornment>
                    ),
                }}
                onKeyDown={(e) => {
                    if (!e.shiftKey && e.key === "Enter" && message !== undefined && message !== "") {
                        setMessage("")
                        props.onSend(message)
                        e.stopPropagation()
                        e.preventDefault();
                    }
                }} />
        </div>
    )
}