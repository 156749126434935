import { Observable, BehaviorSubject } from "rxjs";

export type CreateChannelState = EditState | Saving | Done | Failed | Cancelled

export type EditState = {
    type: 'edit'
    actions: {
        create: (name: string, participants: string[]) => void
        cancel: () => void
    }
}

type Saving = { type: 'saving' }
type Done = { type: 'done', channelId: string }
type Failed = { type: 'failed' }
type Cancelled = { type: 'cancelled' }

export type CreateNewChannel = () => Observable<CreateChannelState>

interface ClientApi {
    createCustomChannel: (name: string, participants: string[]) => Promise<{ id: string }>
}

export const createCreateNewChannelUsecase = (clientApi: ClientApi): CreateNewChannel => {
    const state = new BehaviorSubject<CreateChannelState>({
        type: 'edit',
        actions: {
            create: (name: string, participants: string[]) => {
                const currentState = state.value
                if (currentState.type === 'edit') {
                    if (name.length > 0 && participants.length > 0) {
                        state.next({ type: 'saving' })
                        clientApi.createCustomChannel(name, participants)
                            .then(response => { state.next({ type: 'done', channelId: response.id }) })
                            .catch(_error => {
                                state.next({ type: 'failed' })
                            })
                    }
                }
            },
            cancel: () => {
                state.next({ type: 'cancelled' })
            }
        }
    })

    return () => state
}