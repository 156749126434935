import { BehaviorSubject } from "rxjs"
import { GroupChannelMessage } from "../domain/entities/GroupChannel"

export class GroupMessageStoreImpl {

    private messages: Map<String, GroupChannelMessage[]> = new Map()
    private messagesSubject = new BehaviorSubject<Map<String, GroupChannelMessage[]>>(this.messages)

    addMessage(channelId: string, message: GroupChannelMessage) {
        const oldMessages = this.messages.get(channelId) ?? []
        this.messages.set(channelId, [...oldMessages, message])
        this.messagesSubject.next(this.messages)
    }

    addMessages(channelId: string, messages: GroupChannelMessage[]) {
        const oldMessages = this.messages.get(channelId) ?? []
        this.messages.set(channelId, [...oldMessages, ...messages])
        this.messagesSubject.next(this.messages)
    }

    addMessagesMultiChannel(messages: GroupChannelMessage[]) {
        messages.forEach(message => {
            const old = this.messages.get(message.channelId) ?? []
            this.messages.set(message.channelId, [...old, message])
        })
        this.messagesSubject.next(this.messages)
    }

    getMessages(): BehaviorSubject<Map<String, GroupChannelMessage[]>> {
        return this.messagesSubject
    }

    markAllAsRead(channelId: string) {
        const messages = this.messages.get(channelId)
        if (messages) {
            const readMessages: GroupChannelMessage[] = messages.map(message => {
                return { ...message, read: true }
            })
            this.messages.set(channelId, readMessages)
        }
        this.messagesSubject.next(this.messages)
    }

    markAllMessagesAsRead() {
        this.messages.forEach((value, key) => {
            value.forEach((v, k) => v.read = true)
        })
        this.messagesSubject.next(this.messages)
    }
}