import React, { useContext, useState, useEffect } from 'react';
import { BrowserRouter as Router, Route, Switch, Redirect, RouteProps } from 'react-router-dom';
import { DomainContext, domain, AuthStatus } from './DomainContext';
import { LoginPage } from './ui/login/LoginPage';
import CssBaseline from '@material-ui/core/CssBaseline';
import { ThemeProvider } from "@material-ui/styles";
import { createMuiTheme } from '@material-ui/core/styles';
import { LogoutPage } from './ui/login/LogoutPage';
import { MainView } from './ui/dispatcher/main-view/MainView';
import { KotaDrawer } from './ui/drawer/KotaDrawer';
import { MessagingPage } from './ui/messaging/MessagingPage';
import { LoginCallback } from './ui/login/LoginCallback';
import { ChannelListSettingsProvider } from './SettingsContext';
import { LoginExpiredPage } from './ui/login/LoginExpiredPage';
import { Snackbar } from '@material-ui/core';
import { ConnectionStatus } from './data/EventWebsocket';
import { debounceTime } from 'rxjs/operators';
import { AlarmDialog } from './ui/emergency-alarm/AlarmDialog';
import Alert from '@material-ui/lab/Alert/Alert';

const theme = createMuiTheme({
  palette: {
    primary: {
      light: '#CFF5C4',
      main: '#00B451',
      dark: '#357a38',
      contrastText: '#fff',
    },
    secondary: {
      light: '#B0B4B8',
      main: '#333333',
      dark: '#ba000d',
      contrastText: '#fff',
    },
    error: {
      main: '#E85430'
    }
  },
  typography: {
    body1: {
      color: '#333333',
      fontWeight: 500,
      fontSize: '14px',
      letterSpacing: '-0.05px',
      lineHeight: '21px'
    },
  }
});

const App = () => {
  const params = new URLSearchParams(window.location.search)
  const { initialize } = useContext(DomainContext)

  useEffect(() => {
    initialize()
  }, [initialize])

  return (
    <React.Fragment>
      <CssBaseline />
      <ThemeProvider theme={theme}>
        <DomainContext.Provider value={domain}>
          <ChannelListSettingsProvider>
            <Router>              
              <Switch>
                <PrivateRoute exact path="/" component={MainView} />
                <PrivateRoute exact path="/uusi" component={MainView}>
                  <Redirect to="/" />
                </PrivateRoute>
                <PrivateRoute exact path="/viestit" component={MessagingPage} />
                <Route exact path="/login-callback" component={() => <LoginCallback code={params.get('code')} />} />
                <Route exact path="/login" component={LoginPage} />
                <Route exact path="/logout" component={LogoutPage} />
                <Route exact path="/login-expired" component={LoginExpiredPage} />
                <Route exact path="/uusi/login-callback" component={() => <LoginCallback code={params.get('code')} />} />
                <PrivateRoute path="/uusi/*" component={MainView}>
                  <Redirect to="/" />
                </PrivateRoute>
                <Route path="*" component={GenericNotFound} />
              </Switch>
            </Router>
          </ChannelListSettingsProvider>
        </DomainContext.Provider>
      </ThemeProvider>
    </React.Fragment>
  )
}

interface PrivateRouteProps extends RouteProps {
  component: any;
}

const PrivateRoute = (props: PrivateRouteProps) => {
  const { component: Component, ...rest } = props;

  const { getAuthenticationStatus, getConnectionStatus } = useContext(DomainContext)
  const [authStatus, setAuthStatus] = useState<AuthStatus>(getAuthenticationStatus().value)
  const [connectionStatus, setConnectionStatus] = useState<ConnectionStatus>('connected')

  useEffect(() => {
    const subscription = getAuthenticationStatus().subscribe(setAuthStatus)
    const connectionSubscription = getConnectionStatus().pipe(debounceTime(3000)).subscribe(setConnectionStatus)
    return function cleanup() {
      subscription.unsubscribe()
      connectionSubscription.unsubscribe()
    }
  }, [getAuthenticationStatus, getConnectionStatus])


  let connectionWarning = (<></>)

  if (connectionStatus === 'disconnected') {
    connectionWarning = (
      <Snackbar anchorOrigin={{ vertical: 'top', horizontal: 'center' }} open={true}>
        <Alert variant="filled" severity="warning">
          Reaaliaikainen viestiyhteys on poikki. Viestit voivat ilmestyä noin kahden minuutin viiveellä. Lataa sivu uudestaan.
        </Alert>
      </Snackbar>
    )
  } else if (connectionStatus === 'reconnecting') {
    connectionWarning = (
      <Snackbar anchorOrigin={{ vertical: 'top', horizontal: 'center' }} open={true}>
        <Alert variant="filled" severity="warning">
          Reaaliaikainen viestiyhteys on poikki. Yhteyttä yritetään muodostaa uudestaan. Viestit voivat ilmestyä noin kahden minuutin viiveellä.
        </Alert>
      </Snackbar>
    )
  }

  return (
    <Route
      {...rest}
      render={(routeProps) => {
        switch (authStatus.type) {
          case 'checking-authentication': return (
            <div><p>Ladataan...</p></div>
          )
          case 'unauthenticated': return (
            <Redirect
              to={{
                pathname: '/login',
                state: { from: routeProps.location }
              }}
            />
          )
          case 'expired': return (
            <Redirect
              to={{
                pathname: '/login-expired',
                state: { from: routeProps.location }
              }}
            />
          )
          case 'authenticated': return (
            <div style={{ maxWidth: '100%', height: '100%', backgroundColor: '#abbaafa' }}>
              <KotaDrawer path={props.path}>
                <AlarmDialog />
                <Component {...routeProps} />
              </KotaDrawer>
              {connectionWarning}
            </div>
          )
          case 'missing-permissions': return (
            <div><p>Puuttuva käyttöoikeus. Varmista että käyttätunnuksesi on liitetty tarvittaviin AD-ryhmiin.</p></div>
          )
        }
      }}
    />
  )
}

const GenericNotFound = () => {
  return <h2>Page Not Found</h2>
}

export default App;
