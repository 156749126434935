import React from 'react';
import ReactDOM from 'react-dom';
import './index.css';
import App from './App';
import * as serviceWorker from './serviceWorker';

// TODO: remove this once the old dispatcher page is replaced with this one. Also replace <Router basename='/uusi'> with just <Router> in App.tsx
// TODO: also remove "homepage": "https://kota.vr.fi/uusi" from package.json
// const ROOT_URL = "/uusi/"
// const path = window.location.hash.slice(1);
// if (path) {
//   window.location.hash = '';
//   window.history.replaceState({}, '', `${ROOT_URL}${path}`);
// } else if (window.location.pathname.includes("index.html")) {
//   window.history.replaceState({}, '', `${ROOT_URL}`);
// }

ReactDOM.render(
  <React.StrictMode>
    <App />
  </React.StrictMode>,
  document.getElementById('root')
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
