import React, { useState, useEffect, useContext } from 'react';
import { Redirect } from 'react-router';
import { DomainContext } from '../../DomainContext';

interface LoginProps {
    code: string | undefined | null
}

type LoginStatus = "in-progress" | "failed" | "success";

export const LoginCallback: React.FunctionComponent<LoginProps> = (props) => {

    const [progress, setProgress] = useState<LoginStatus>('in-progress')

    const { exchangeLoginCodeToToken } = useContext(DomainContext)

    useEffect(() => {
        const exchangeToken = async () => {
            if (props.code) {
                const result = await exchangeLoginCodeToToken(props.code)
                if (result.success === true) {
                    setProgress('success')
                } else {
                    setProgress('failed')
                }
            }
        }

        exchangeToken()
    }, [exchangeLoginCodeToToken, props.code]);

    if (progress === 'in-progress') {
        return (
            <div>
                <h2>Kirjaudutaan... Ole hyvä ja odota hetki.</h2>
            </div>
        )
    } else if (progress === 'success') {
        return <Redirect to="/" />;
    } else {
        return (
            <div>
                <h2>Kirjautuminen epäonnistui</h2>
            </div>
        )
    }
}