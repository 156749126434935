import { BehaviorSubject, Observable, defer, Subject } from "rxjs";
import { GroupChannel, GroupChannelMessage } from "../domain/entities/GroupChannel";
import { map, retryWhen, delay, take } from "rxjs/operators";
import { combineLatest } from 'rxjs';
import { AuthResponse } from "../network/ClientApi";

interface ChatChannelStore {
    getChannel: (id: string) => GroupChannel | undefined
}

interface MessageStore {
    getMessages: () => BehaviorSubject<Map<String, GroupChannelMessage[]>>
    markAllAsRead: (channelId: string) => void
    addMessage: (channelId: string, message: GroupChannelMessage) => void
}

interface ClientApi {
    sendMessageToChannel: (channelId: string, message: string) => Promise<void>
}

interface PersistentStorage {
    getAuthDetails: () => AuthResponse | undefined
}

export type GroupChannelState = {
    type: 'group-channel-loaded',
    state: {
        channel: GroupChannel
        messages: GroupChannelMessage[]
    }
    actions: {
        sendMessage: (message: string) => void
    }
} | {
    type: 'unknown-channel'
}

export type StatusNotification = 'sending-message-failed'

export type ChatInGroupChannel = (channelId: string) => Observable<GroupChannelState>

export const createChatInGroupChannelUseCase = (channelStore: ChatChannelStore, messageStore: MessageStore, clientApi: ClientApi, persistentStorage: PersistentStorage): ChatInGroupChannel => {

    let pendingMessageList = new BehaviorSubject<GroupChannelMessage[]>([])

    let statusNotifications = new Subject<StatusNotification>()

    function sendMessage(channelId: string, message: string) {
        const authDetails = persistentStorage.getAuthDetails()!

        const existingPending = pendingMessageList.value

        pendingMessageList.next([...existingPending, {
            type: 'group-channel-message',
            id: 'pending-' + Date.now(),
            channelId,
            message,
            from: {
                name: authDetails.user.firstName + ' ' + authDetails.user.lastName,
                id: authDetails.user.id,
                type: 'dispatcher'
            },
            timestamp: Date.now(),
            read: true,
            savedToBackend: false
        }])

        defer(() => { return clientApi.sendMessageToChannel(channelId, message) })
            .pipe(
                retryWhen(errors => errors.pipe(delay(7000), take(3))) // TODO: stop trying if the message is received through websocket? Also notify user if the message cannot be sent
            )
            .subscribe(
                data => { },
                _err => {
                    statusNotifications.next('sending-message-failed')
                }
            )
    }

    return (channelId) => {

        const channel = channelStore.getChannel(channelId)

        let state: Observable<GroupChannelState> = combineLatest(
            messageStore.getMessages(),
            pendingMessageList
        )
            .pipe(map(val => {
                const [messagesGroupedByChannel, pendingMessages] = val
                if (channel === undefined) {
                    return { type: 'unknown-channel' } as GroupChannelState
                }

                const channelMessages = messagesGroupedByChannel.get(channelId) ?? []
                const pending = pendingMessages.filter(message => channelMessages.find(m => m.message === message.message && m.read === false) === undefined)

                if (pendingMessages.length > pending.length) {
                    pendingMessageList.next(pending)
                }

                const sortedMessages = [...channelMessages, ...pending].sort((a, b) => a.timestamp - b.timestamp)

                if (sortedMessages.find(m => m.read === false)) {
                    messageStore.markAllAsRead(channelId)
                }

                return {
                    type: 'group-channel-loaded',
                    state: {
                        channel,
                        messages: sortedMessages
                    },
                    actions: {
                        sendMessage: (message) => sendMessage(channelId, message)
                    }
                } as GroupChannelState
            }))

        return state
    }
} 