import { BehaviorSubject } from "rxjs";
import { DispatcherTask } from "../domain/entities/DispatcherTask";

export class TaskStore {

    private tasks = new BehaviorSubject<DispatcherTask[]>([])

    saveTasks(tasks: DispatcherTask[]) {
        this.tasks.next(tasks)
    }

    getOpenTasks(): DispatcherTask[] {
        return this.tasks.value.filter(it => it.status === 'open')
    }

    observeTasks(): BehaviorSubject<DispatcherTask[]> {
        return this.tasks
    }
}