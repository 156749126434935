import { BehaviorSubject, Observable } from "rxjs";
import { GroupChannelMessage, GroupChannel } from "../domain/entities/GroupChannel";
import { map } from "rxjs/operators";
import { combineLatest } from 'rxjs';
import { PersonalMessage } from "../domain/entities/PersonalMessage";
import { GeneralNotification } from "../domain/entities/GeneralNotification";

interface MessageStore {
    getMessages: () => BehaviorSubject<Map<String, GroupChannelMessage[]>>
    markAllMessagesAsRead: () => void
}

interface PersonalMessageStore {
    getMessages: () => BehaviorSubject<Map<String, PersonalMessage[]>>
    markAllMessagesAsRead: () => void
}

interface NotificationStore {
    getNotifications: () => BehaviorSubject<GeneralNotification[]>
}

interface GroupChannelStore {
    observeChannels: () => BehaviorSubject<GroupChannel[]>
}

export type MessageInfo = {
    type: 'group-message'
    message: GroupChannelMessage,
    channel: GroupChannel | undefined
} | {
    type: 'personal-message',
    message: PersonalMessage
} | {
    type: 'notification'
    message: GeneralNotification
}

export type ShowLatestMessagesState = {
    type: 'show-latest-messages'
    messages: MessageInfo[]
    markAllAsRead: () => void
}

export type ShowLatestMessages = () => Observable<ShowLatestMessagesState>

export const createShowLatestMessagesUseCase = (chatChannelStore: GroupChannelStore, messageStore: MessageStore, personalMessageStore: PersonalMessageStore, notificationStore: NotificationStore): ShowLatestMessages => () => {

    let state: Observable<ShowLatestMessagesState> = combineLatest(
        chatChannelStore.observeChannels(),
        messageStore.getMessages(),
        personalMessageStore.getMessages(),
        notificationStore.getNotifications()

    )
        .pipe(map(val => {
            const [channels, messagesGroupedByChannel, personalMessagesGroupedByPerson, notifications] = val

            let messages: MessageInfo[] = []

            if (channels.length === 0) {
                return {
                    type: 'show-latest-messages',
                    messages: [],
                    markAllAsRead: () => {
                        messageStore.markAllMessagesAsRead()
                        personalMessageStore.markAllMessagesAsRead()
                    }
                }
            }

            // TODO: Horribly inefficient. Optimize
            messagesGroupedByChannel.forEach((value, key) => {
                messages.push(...value.map(v => {
                    return {
                        type: 'group-message',
                        message: v,
                        channel: channels.find(c => c.id === key)
                    } as MessageInfo
                }))
            })

            personalMessagesGroupedByPerson.forEach((value, key) => messages.push(...value.map(m => {
                return {
                    type: 'personal-message',
                    message: m
                } as MessageInfo
            })))
            messages.push(...notifications.map(n => { return { type: 'notification', message: n } as MessageInfo }))

            let sorted = messages.sort((a, b) => a.message.timestamp - b.message.timestamp)

            if (sorted.length > 50) {
                sorted = sorted.slice(sorted.length - 50)
            }

            return {
                type: 'show-latest-messages',
                messages: sorted,
                markAllAsRead: () => {
                    messageStore.markAllMessagesAsRead()
                    personalMessageStore.markAllMessagesAsRead()
                }
            }
        }))

    return state

} 