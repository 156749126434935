import React, { useEffect, useRef } from "react"
import { makeStyles, createStyles, Theme, List } from "@material-ui/core"
import { Message } from "../../../domain/entities/Message"
import { MessageElement } from "./Message"

const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        root: {
            height: `calc(100% - 75px)`,
            maxHeight: `calc(100% - 75px)`,
            width: '100%',
            position: 'relative',
            overflow: 'auto',
            backgroundColor: theme.palette.background.paper
        },
    }),
)

type Props = {
    messages: Message[]
}

export const MessageList = (props: Props) => {
    const classes = useStyles()

    const messagesEndRef = useRef<HTMLDivElement>(null)

    const scrollToBottom = () => {
        messagesEndRef.current?.scrollIntoView({ behavior: 'auto' })
    }

    useEffect(() => {
        scrollToBottom()
    }, [messagesEndRef, props])

    return (
        <List className={classes.root}>
            {props.messages.map((message) => (<MessageElement key={message.timestamp} message={message} />)
            )}
            <div ref={messagesEndRef} />
        </List>
    )
}