import { BehaviorSubject, Observable, defer, Subject } from "rxjs";
import { map, retryWhen, delay, take } from "rxjs/operators";
import { combineLatest } from 'rxjs';
import { AuthResponse } from "../network/ClientApi";
import { GeneralNotification } from "../domain/entities/GeneralNotification";

interface NotificationStore {
    getNotifications: () => BehaviorSubject<GeneralNotification[]>
}

interface PersistentStorage {
    getAuthDetails: () => AuthResponse | undefined
}

interface ClientApi {
    sendGeneralNotification: (message: string) => Promise<void>
}

export type GeneralNotificationsState = {
    type: 'general-notifications-loaded',
    state: {
        notifications: GeneralNotification[]
    }
    actions: {
        sendMessage: (message: string) => void
    }
}

export type StatusNotification = 'sending-message-failed'

export type SendNotifications = () => Observable<GeneralNotificationsState>

export const createSendNotificationsUseCase = (notificationStore: NotificationStore, clientApi: ClientApi, persistentStorage: PersistentStorage): SendNotifications => {

    let pendingMessageList = new BehaviorSubject<GeneralNotification[]>([])

    let statusNotifications = new Subject<StatusNotification>()

    function sendMessage(message: string) {
        const authDetails = persistentStorage.getAuthDetails()!
        const existingPending = pendingMessageList.value

        pendingMessageList.next([...existingPending, {
            type: 'general-notification',
            id: 'pending-' + Date.now(),
            message,
            from: {
                name: authDetails.user.firstName + ' ' + authDetails.user.lastName,
                id: authDetails.user.id,

            },
            timestamp: Date.now(),
            read: true,
            savedToBackend: false
        }])

        defer(() => { return clientApi.sendGeneralNotification(message) })
            .pipe(
                retryWhen(errors => errors.pipe(delay(7000), take(2)))
            )
            .subscribe(
                data => { },
                _err => {
                    statusNotifications.next('sending-message-failed')
                }
            )
    }

    return () => {

        let state: Observable<GeneralNotificationsState> = combineLatest(
            notificationStore.getNotifications(),
            pendingMessageList
        )
            .pipe(map(val => {
                const [notifications, pendingMessages] = val

                const pending = pendingMessages.filter(message => notifications.find(m => m.message === message.message && m.read === false) === undefined)

                if (pendingMessages.length > pending.length) {
                    pendingMessageList.next(pending)
                }

                const sortedMessages = [...notifications, ...pending].sort((a, b) => a.timestamp - b.timestamp)

                return {
                    type: 'general-notifications-loaded',
                    state: {
                        notifications: sortedMessages
                    },
                    actions: {
                        sendMessage: (message) => sendMessage(message)
                    }
                }
            }))

        return state
    }
} 