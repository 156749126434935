import { ObserveChannels, PersonalChannel, ObserveChannelsState } from '../../usecases/ObserveChannels';
import { GroupChannel, GroupChannelMessage } from '../../domain/entities/GroupChannel';
import { Observable, BehaviorSubject, combineLatest, of } from 'rxjs';
import { ChatInGroupChannel } from '../../usecases/ChatInGroupChannel';
import { map, switchMap, filter } from 'rxjs/operators';
import { PersonalMessage } from '../../domain/entities/PersonalMessage';
import { GeneralNotification } from '../../domain/entities/GeneralNotification';
import { ChatInPersonalChannel } from '../../usecases/ChatInPersonalChannel';
import { SendNotifications } from '../../usecases/SendNotifications';
import { CreateNewChannel, CreateChannelState } from '../../usecases/CreateNewChannel';
import { ShowLatestMessages, MessageInfo } from '../../usecases/ShowLatestMessages';

export type SideBarState = {
    channels: ObserveChannelsState
    createNewChannel: () => void
    startPersonalConversation: () => void
    selectLatestMessages: () => void
    selectSearch: () => void
    selectChannel: (id: string) => void
    selectPersonalChannel: (id: string) => void
    selectNotifications: () => void
}

export type MessagingState = {
    type: 'loading'
} | {
    type: 'no-channel-selected'
    sideBarState: SideBarState
    dialogState: DialogState
} | {
    type: 'group-channel-selected'
    channel: GroupChannel
    messages: GroupChannelMessage[]
    sideBarState: SideBarState
    dialogState: DialogState
    actions: {
        sendMessage: (message: string) => void
    }
} | {
    type: 'personal-channel-selected'
    channel: PersonalChannel
    messages: PersonalMessage[]
    sideBarState: SideBarState
    dialogState: DialogState
    actions: {
        sendMessage: (message: string) => void
    }
} | {
    type: 'general-notifications-selected'
    notifications: GeneralNotification[]
    sideBarState: SideBarState
    dialogState: DialogState
    actions: {
        sendMessage: (message: string) => void
    }
} | {
    type: 'show-latest-messages'
    messages: MessageInfo[]
    sideBarState: SideBarState
    dialogState: DialogState
    actions: {
        markAllAsRead: () => void
    }
}

export type CurrentMainTask = {
    type: 'group-channel-selected'
    id: string
} | {
    type: 'personal-channel-selected'
    id: string
} | {
    type: 'general-notifications-selected'
} | {
    type: 'show-latest-messages'
} | undefined

type DialogTask = {
    type: 'create-new-channel'
} | {
    type: 'start-personal-conversation'
} | {
    type: 'search'
} | undefined

export type DialogState = CreateNewChannelDialogState | StartPersonalConversationDialogState | SearchState | undefined

type CreateNewChannelDialogState = {
    type: 'create-new-channel'
    state: CreateChannelState
}

type StartPersonalConversationDialogState = {
    type: 'start-personal-conversation'
    onPersonSelected: (id: string) => void
    onCancelled: () => void
}

type SearchState = {
    type: 'search'
    onClose: () => void
    onSelectGroupChannel: (id: string) => void
}

export const createMessagingViewModel = (
    observeChannels: ObserveChannels,
    chatInGroupChannel: ChatInGroupChannel,
    chatInPersonalChannel: ChatInPersonalChannel,
    sendNotifications: SendNotifications,
    createNewChannel: CreateNewChannel,
    showLatestMessages: ShowLatestMessages
) => (): Observable<MessagingState> => {

    const currentMainTask = new BehaviorSubject<CurrentMainTask>(undefined)

    const currentDialogTask = new BehaviorSubject<DialogTask>(undefined)

    const currentTaskState = currentMainTask.pipe(
        switchMap(selection => {
            switch (selection?.type) {
                case undefined: return of(undefined)
                case 'group-channel-selected': return chatInGroupChannel(selection.id)
                case 'personal-channel-selected': return chatInPersonalChannel(selection.id)
                case 'general-notifications-selected': return sendNotifications()
                case 'show-latest-messages': return showLatestMessages()
            }
        })
    )

    const currentDialogTaskState: Observable<DialogState> = currentDialogTask.pipe(
        switchMap(task => {
            switch (task?.type) {
                case undefined: return of(undefined)
                case 'create-new-channel': return createNewChannel()
                    .pipe(map(val => {
                        return { type: 'create-new-channel', state: val } as CreateNewChannelDialogState
                    }))
                case 'start-personal-conversation': return of({
                    type: 'start-personal-conversation',
                    onPersonSelected: (id: string) => {
                        currentDialogTask.next(undefined)
                        currentMainTask.next({ type: 'personal-channel-selected', id: id })
                    },
                    onCancelled: () => currentDialogTask.next(undefined)
                } as StartPersonalConversationDialogState)
                case 'search': return of({
                    type: 'search',
                    onClose: () => currentDialogTask.next(undefined),
                    onSelectGroupChannel: (id: string) => {
                        currentDialogTask.next(undefined)
                        currentMainTask.next({ type: 'group-channel-selected', id: id })
                    }
                } as SearchState)
            }
        }))

    const state: Observable<MessagingState | null> = combineLatest(observeChannels(), currentTaskState, currentDialogTaskState)
        .pipe(map(val => {
            const [channels, currentTask, dialogState] = val

            if (channels.type === 'loading') {
                return { type: 'loading' }
            }

            if (dialogState?.type === 'create-new-channel') {
                switch (dialogState.state.type) {
                    case 'done': {
                        currentDialogTask.next(undefined)
                        currentMainTask.next({ type: 'group-channel-selected', id: dialogState.state.channelId })
                        break
                    }
                    case 'failed': {
                        // TODO: show error message
                        currentDialogTask.next(undefined)
                        break
                    }
                }
            }

            const sideBarState: SideBarState = {
                channels,
                createNewChannel: () => currentDialogTask.next({ type: 'create-new-channel' }),
                startPersonalConversation: () => currentDialogTask.next({ type: 'start-personal-conversation' }),
                selectLatestMessages: () => currentMainTask.next({ type: 'show-latest-messages' }),
                selectSearch: () => currentDialogTask.next({ type: 'search' }),
                selectChannel: (id: string) => currentMainTask.next({ type: 'group-channel-selected', id }),
                selectPersonalChannel: (id: string) => currentMainTask.next({ type: 'personal-channel-selected', id }),
                selectNotifications: () => currentMainTask.next({ type: 'general-notifications-selected' }),
            }

            switch (currentTask?.type) {
                case 'unknown-channel':
                case undefined: return {
                    type: 'no-channel-selected',
                    sideBarState,
                    dialogState,
                } as MessagingState
                case 'group-channel-loaded': {
                    const state: MessagingState = {
                        type: 'group-channel-selected',
                        channel: currentTask.state.channel,
                        messages: currentTask.state.messages,
                        sideBarState,
                        dialogState,
                        actions: {
                            sendMessage: (message: string) => currentTask.actions.sendMessage(message)
                        }
                    }
                    return state
                }
                case 'personal-channel-loaded': {
                    const state: MessagingState = {
                        type: 'personal-channel-selected',
                        channel: currentTask.state.channel,
                        messages: currentTask.state.messages,
                        sideBarState,
                        dialogState,
                        actions: {
                            sendMessage: (message: string) => currentTask.actions.sendMessage(message)
                        }
                    }
                    return state
                }
                case 'general-notifications-loaded': return {
                    type: 'general-notifications-selected',
                    notifications: currentTask.state.notifications,
                    sideBarState,
                    dialogState,
                    actions: {
                        sendMessage: (message: string) => currentTask.actions.sendMessage(message)
                    }
                } as MessagingState
                case 'show-latest-messages': return {
                    type: 'show-latest-messages',
                    messages: currentTask.messages,
                    sideBarState,
                    dialogState,
                    actions: {
                        markAllAsRead: () => currentTask.markAllAsRead()
                    }
                } as MessagingState
            }
        }))

    return state.pipe(filter(isNonNull))
}

export function isNonNull<T>(value: T): value is NonNullable<T> {
    return value != null;
}