import React from 'react';
import { ActionsComponent } from './ActionsComponent';
import { ChannelList } from './ChannelList';
import { SideBarState } from '../MessagingViewModel';

type Props = {
    selectedChannel: { type: 'group' | 'personal', id: string } | undefined
    state: SideBarState
}

export const SideBar = (props: Props) => {
    let unreadMessages = 0
    if (props.state.channels.type === 'loaded') {
        unreadMessages = props.state.channels.numberOfUnreadMessages
    }

    return (
        <div style={{ height: '100%' }}>
            <ActionsComponent showSearch={props.state.selectSearch} showNotifications={props.state.selectNotifications} showLatestMessages={props.state.selectLatestMessages} numberOfUnreadMessages={unreadMessages} />
            <ChannelList selectedChannel={props.selectedChannel} state={props.state} />
        </div>
    )
}
