import { BehaviorSubject } from "rxjs";
import { ActiveUser } from "../domain/entities/ActiveUser";

export class ActiveUsersStore {

    private users = new BehaviorSubject<ActiveUser[]>([])

    updateUsers(activeUsers: ActiveUser[]) {
        this.users.next(activeUsers)
    }

    observeActiveUsers(): BehaviorSubject<ActiveUser[]> {
        return this.users
    }

    getActiveUsers(): ActiveUser[] {
        return this.users.value
    }

}