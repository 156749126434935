import React from 'react';
import { makeStyles, ListItem, ListItemText, Divider } from '@material-ui/core';

const useStyles = makeStyles((theme) => ({
    root: {
        width: '100%',
        backgroundColor: theme.palette.background.paper,
    },
    inline: {
        display: 'inline',
    }
}))

type Props = {
    channelName: string
    subtitle: string
}

export const ChannelHeader = (props: Props) => {
    const classes = useStyles()

    return (
        <>
            <ListItem className={classes.root}>
                <ListItemText primary={props.channelName} secondary={props.subtitle} />
            </ListItem>
            <Divider />
        </>
    )
}
