import React, { useState, useContext, useEffect } from 'react';
import { Button, Dialog, DialogTitle, DialogContent, TextField, DialogActions } from '@material-ui/core';
import { CreateChannelState, EditState } from '../../../usecases/CreateNewChannel';
import Autocomplete from '@material-ui/lab/Autocomplete';
import { DomainContext } from '../../../DomainContext';
import { ActiveUser } from '../../../domain/entities/ActiveUser';

export type Props = {
    state: CreateChannelState
}

export const NewChannelDialog = (props: Props) => {
    const [channelName, setChannelName] = useState<string>('')

    const [users, setUsers] = useState<ActiveUser[]>([])

    const [selectedUsers, setSelectedUsers] = useState<ActiveUser[]>([])

    const { activeUsersStore } = useContext(DomainContext)

    useEffect(() => {
        setUsers(activeUsersStore.getActiveUsers())
    }, [activeUsersStore])

    const handleNameChange = (event: any) => {
        const { value } = event.target;
        setChannelName(value)
    }

    if (props.state.type === 'edit') {
        const state: EditState = props.state

        return (
            <Dialog fullWidth maxWidth="sm" open={true} onClose={() => { }} aria-labelledby="form-dialog-title">
                <DialogTitle id="form-dialog-title">Luo uusi keskusteluryhmä</DialogTitle>
                <DialogContent>
                    <TextField
                        autoFocus
                        autoComplete="off"
                        margin="dense"
                        id="name"
                        label="Ryhmän nimi"
                        fullWidth
                        value={channelName}
                        onChange={handleNameChange}
                    />
                    <p>{" "}</p>
                    <Autocomplete
                        multiple
                        id="tags-outlined"
                        options={users}
                        getOptionLabel={(option) => option.firstName + " " + option.lastName + " (" + option.id + ")"}
                        filterSelectedOptions
                        onChange={(event: any, newValue: ActiveUser[] | null) => {
                            setSelectedUsers(newValue ?? [])
                        }}
                        renderInput={(params) => (
                            <TextField
                                {...params}
                                variant="outlined"
                                label="Valitse konduktöörit"
                                placeholder="Henkilö"
                            />
                        )}
                    />
                </DialogContent>
                <DialogActions>
                    <Button onClick={state.actions.cancel} color="primary">
                        Peruuta
                </Button>
                    <Button onClick={() => state.actions.create(channelName, selectedUsers.map(u => u.id))} color="primary">
                        Luo kanava
                </Button>
                </DialogActions>
            </Dialog>
        )
    } else if (props.state.type === 'saving') {
        return (
            <Dialog open={true} onClose={() => { }} aria-labelledby="form-dialog-title">
                <DialogTitle id="form-dialog-title">Luodaan kanavaa...</DialogTitle>
            </Dialog>
        )
    } else {
        return <></> // TODO: fail message
    }
}
