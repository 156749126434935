import { useContext } from "react"
import { Item, ChannelListRow } from "./ChannelListRow"
import React from "react"
import { ChannelListSettingsContext } from "../../../SettingsContext"
import { FixedSizeList } from 'react-window';
import AutoSizer from "react-virtualized-auto-sizer";
import { makeStyles, createStyles, Theme } from "@material-ui/core"
import { SideBarState } from "../MessagingViewModel"

type Props = {
    selectedChannel: { type: 'group' | 'personal', id: string } | undefined
    state: SideBarState
}

const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        root: {
            height: `calc(100% - 148px)`,
            width: 275,
            backgroundColor: '#F2F2F2'
        },
    }),
)

export const ChannelList = (props: Props) => {

    const classes = useStyles()
    const { settings, toggle } = useContext(ChannelListSettingsContext)
    const channels = props.state.channels
    let items: Item[] = []

    items.push({
        type: 'category',
        name: 'Uusia viestejä kanavilla',
        open: settings.showUnreadChannels,
        toggle: () => toggle('unread'),
        action: undefined,
        numberOfMessages: 0
    })

    if (settings.showUnreadChannels && channels.type === 'loaded') {
        channels.unreadGroupChannels.forEach(group => items.push({
            type: group.type === 'work-shift' ? 'work-shift-channel' : 'custom-channel',
            channel: group,
            select: () => props.state.selectChannel(group.id)
        }))

        channels.unreadPersonalChannels.forEach(channel => items.push({
            type: 'personal-channel',
            channel: channel,
            select: () => props.state.selectPersonalChannel(channel.personId)
        }))
    }

    items.push({
        type: 'category',
        name: 'Erityiskanavat',
        open: settings.showCustomChannels,
        toggle: () => toggle('custom'),
        action: () => { props.state.createNewChannel() }
    })

    if (settings.showCustomChannels && channels.type === 'loaded') {
        channels.groupChannels
            .filter(channel => channel.type === 'custom' && (channel.numberOfUnreadMessages ?? 0) === 0)
            .forEach(channel => items.push({
                type: 'custom-channel',
                channel,
                select: () => props.state.selectChannel(channel.id)
            }))
    }

    items.push({
        type: 'category',
        name: 'Työvuorokanavat',
        open: settings.showWorkShiftChannels,
        toggle: () => toggle('workShift'),
        action: undefined
    })

    if (settings.showWorkShiftChannels && channels.type === 'loaded') {
        channels.groupChannels
            .filter(channel => channel.type === 'work-shift' && (channel.numberOfUnreadMessages ?? 0) === 0)
            .forEach(channel => items.push({
                type: 'work-shift-channel',
                channel,
                select: () => props.state.selectChannel(channel.id)
            }))
    }

    items.push({
        type: 'category',
        name: 'Henkilökohtaiset viestit',
        open: settings.showPersonalChannels,
        toggle: () => toggle('personal'),
        action: () => { props.state.startPersonalConversation() }
    })

    if (settings.showPersonalChannels && channels.type === 'loaded') {
        channels.personalChannels
            .filter(channel => (channel.numberOfUnreadMessages ?? 0) === 0)
            .forEach(channel => items.push({
                type: 'personal-channel',
                channel,
                select: () => props.state.selectPersonalChannel(channel.personId)
            }))
    }

    return (
        <div className={classes.root}>
            <AutoSizer>
                {({ height }) => (
                    <FixedSizeList height={height} width={275} itemSize={46} itemCount={items.length}>
                        {ChannelListRow(items)}
                    </FixedSizeList>
                )}
            </AutoSizer>
        </div>
    )
}