import { BehaviorSubject, Observable } from "rxjs";
import { GroupChannel, GroupChannelMessage } from "../domain/entities/GroupChannel";
import { map, debounceTime } from "rxjs/operators";
import { combineLatest } from 'rxjs';
import { PersonalMessage } from "../domain/entities/PersonalMessage";
import moment from 'moment-timezone';
import { ActiveUsersStore } from "../data/ActiveUsersStore";

interface ChatChannelStore {
    observeChannels: () => BehaviorSubject<GroupChannel[]>
    getLastUpdated: () => number | undefined
}

interface MessageStore {
    getMessages: () => BehaviorSubject<Map<String, GroupChannelMessage[]>>
}

interface PersonalMessageStore {
    getMessages: () => BehaviorSubject<Map<String, PersonalMessage[]>>
}

export type PersonalChannel = {
    personId: string
    personName?: string
    numberOfUnreadMessages?: number
}

export type ObserveChannelsState = {
    type: 'loading',
} | {
    type: 'loaded',
    groupChannels: GroupChannel[]
    personalChannels: PersonalChannel[]
    unreadGroupChannels: GroupChannel[]
    unreadPersonalChannels: PersonalChannel[]
    numberOfUnreadMessages: number
}

export type ObserveChannels = () => Observable<ObserveChannelsState>

export const createObserveChannelsUseCase = (
    channelStore: ChatChannelStore,
    messageStore: MessageStore,
    personalMessageStore: PersonalMessageStore,
    activeUsersStore: ActiveUsersStore
): ObserveChannels => () => {
    let state: Observable<ObserveChannelsState> = combineLatest(
        channelStore.observeChannels(),
        messageStore.getMessages(),
        personalMessageStore.getMessages()
    )
        .pipe(map(val => {
            const [channels, messagesGroupedByChannel, personalMessages] = val

            const users = activeUsersStore.getActiveUsers()

            if (channels.length === 0 && channelStore.getLastUpdated() === undefined) {
                return { type: 'loading' }
            } else {
                let countOfUnreadMessages = 0
                let unreadGroups: GroupChannel[] = []
                let unreadPersonalChannels: PersonalChannel[] = []

                const sortedChannels = channels
                    .filter(it => {
                        const messages = messagesGroupedByChannel.get(it.id)
                        if (messages && messages.length > 0) {
                            return messages[messages.length - 1].timestamp > Date.now() - 1000 * 60 * 60 * 24
                        }
                        return it.createdAt > Date.now() - 1000 * 60 * 60 * 24
                    })
                    .sort((a, b) => {
                        const time1 = moment(a.createdAt).tz("Europe/Helsinki")
                        const time2 = moment(b.createdAt).tz("Europe/Helsinki")

                        if (time2.dayOfYear() === time1.dayOfYear()) {
                            return a.name.localeCompare(b.name)
                        } else {
                            return b.createdAt - a.createdAt
                        }
                    })
                    .map(channel => {
                        const numberOfUnreadMessages = messagesGroupedByChannel.get(channel.id)?.filter(message => message.read === false)?.length ?? 0
                        return {
                            ...channel,
                            numberOfUnreadMessages
                        }
                    })

                sortedChannels.forEach(channel => {
                    if (channel.numberOfUnreadMessages > 0) {
                        unreadGroups.push(channel)
                        countOfUnreadMessages += channel.numberOfUnreadMessages
                    }
                })


                let personalChannels: PersonalChannel[] = []
                personalMessages.forEach(all => {
                    let userName: string | undefined = undefined
                    if (all.length > 0 && all[all.length - 1].timestamp > Date.now() - 1000 * 60 * 60 * 24) {
                        let user = users.find(u => u.id === all[0].personId)
                        if (user) {
                            userName = user.firstName + ' ' + user.lastName
                        }

                        const unread = all.filter(message => message.read === false)

                        personalChannels.push({
                            personId: all[0].personId,
                            personName: userName,
                            numberOfUnreadMessages: unread.length
                        })

                        countOfUnreadMessages += unread.length

                        const anyUnreadMessage = unread.length > 0 ? unread[0] : undefined
                        if (anyUnreadMessage) {
                            unreadPersonalChannels.push({ personId: anyUnreadMessage.personId, personName: userName, numberOfUnreadMessages: unread.length })
                        }
                    }
                })

                return {
                    type: 'loaded',
                    groupChannels: sortedChannels,
                    unreadGroupChannels: unreadGroups,
                    personalChannels: personalChannels,
                    unreadPersonalChannels: unreadPersonalChannels,
                    numberOfUnreadMessages: countOfUnreadMessages
                } as ObserveChannelsState
            }
        }))

    return state.pipe(debounceTime(700))
}